import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextInputField, Select, Button, Paragraph, toaster, Table, Badge } from 'evergreen-ui';
import { TrashCan } from 'akar-icons';
import HeaderLeft from '../components/headerLeft';
import { gapi } from 'gapi-script';

const clientId = '503430858263-r5o4gdeg91d6ucf20ecn09568975fjf1.apps.googleusercontent.com';
const apiKey = 'AIzaSyAkaQz1AJ_C0jh951CbgSV6UB-9H_NKqXE';
const calendarId = '165bcc1c82aa57be1684677ab30f6aa9b1d9f77043852ef532a6b000ead45f5a@group.calendar.google.com';


const GoogleMeetConference = (props) => {
    const data = props.data;
    const profile = props.profile;

    const [isLoaded, setIsLoaded] = useState(false);
    const [dataEvents, setDataEvents] = useState([]);
    const [dataEmails, setDataEmails] = useState([]);
    const [startTime, setStartTime] = useState('');
    const [eventTitle, setEventTitle] = useState('');
    const [eventType, setEventType] = useState('open');
    const [eventTime, setEventTime] = useState(false);
    const [meetingLink, setMeetingLink] = useState('');

    // const sendInvitations = async (attendees) => {
    //     const message = [
    //         'Content-Type: text/plain; charset="UTF-8"\n',
    //         'MIME-Version: 1.0\n',
    //         'Content-Transfer-Encoding: 7bit\n',
    //         'to: recipient@example.com\n',
    //         'subject: Test email\n\n',
    //         'This is the content of the email.',
    //     ].join('');

    //     const encodedMessage = btoa(message).replace(/\+/g, '-').replace(/\//g, '_');

    //     gapi.client.gmail.users.messages.send({
    //         userId: 'me',
    //         resource: {
    //             raw: encodedMessage,
    //         },
    //     }).then(() => {
    //         console.log('Email sent successfully.');
    //     }).catch((error) => {
    //         console.error('Error sending email:', error);
    //     });
    // };


    useEffect(() => {
        const initializeGapi = () => {
            window.gapi.load('client:auth2', () => {
                window.gapi.client.init({
                    apiKey: apiKey,
                    clientId: clientId,
                    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
                    scope: 'https://www.googleapis.com/auth/calendar',
                }).then(() => {
                    setIsLoaded(true);
                }).catch((error) => {
                    console.error('Error loading Google API client:', error);
                });
            });
            // gapi.load('client:auth2', () => {
            //     gapi.client.init({
            //         apiKey: apiKey,
            //         clientId: clientId,
            //         discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'],
            //         scope: 'https://www.googleapis.com/auth/gmail.send',
            //     }).then(() => {
            //         gapi.client.load('gmail', 'v1', () => {
            //             // В этом месте вы можете использовать gapi.client.gmail
            //         });
            //     });
            // });
        };

        initializeGapi();
    }, []);

    const createMeeting = async () => {
        console.log('createMeeting')
        if (!startTime) {
            console.error('Start time is required');
            return;
        }


        const isoDateTime = new Date(startTime).toISOString();

        try {
            const response = await window.gapi.client.calendar.events.insert({
                calendarId: calendarId,
                resource: {
                    summary: eventTitle || 'Google Meet Conference',
                    start: {
                        dateTime: isoDateTime,
                        timeZone: 'Europe/Moscow',
                    },
                    end: {
                        dateTime: isoDateTime,
                        timeZone: 'Europe/Moscow',
                    },
                    conferenceData: {
                        createRequest: {
                            requestId: 'YOUR_REQUEST_ID',
                        },
                    },
                    visibility: eventType === 'open' ? 'public' : 'private',
                },
                conferenceDataVersion: 1,
            });

            const createdMeetingLink = response.result.hangoutLink;
            setMeetingLink(createdMeetingLink);
            addWebinarCalendar(createdMeetingLink, eventTitle);
            toaster.success('Вебинар успешно создан и добавлен в календарь!', {
                id: 'forbidden-action',
            })
            console.log('Meeting created:', createdMeetingLink);
        } catch (error) {
            console.error('Error creating meeting:', error);
        }
    };




    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value);
        console.log(e.target.value);
    };

    const handleEventTitleChange = (e) => {
        setEventTitle(e.target.value);
    };

    const handleEventTypeChange = (e) => {
        setEventType(e.target.value);
    };

    const handleEventTimeChange = (e) => {
        if (e.target.value === 1) {
            setEventTime(false);
        } else {
            setEventTime(true);
        }
    };


    const addWebinarCalendar = (linkEvent, description) => {
        axios.post("https://reps-club.com:8000/v1/add/event/webinar", {
            "dateEvent": new Date(startTime).toISOString(),
            "description": description,
            "linkEvent": linkEvent
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem("accessToken")
            }
        })
            .then((response) => {
                console.log('yes');
            })
            .catch((error) => {
                toaster.danger('Что-то пошло не так, попробуйте обновить страницу', {
                    id: 'forbidden-action',
                })
            });
    }


    // const createMeetingMail = async () => {

      

    //     if (!startTime) {
    //         console.error('Start time is required');
    //         return;
    //       }
        
    //       const isoDateTime = new Date(startTime);
    //       const isoDateTimePlus60Minutes = new Date(startTime);
          
    //       if (eventTime) {
    //         isoDateTimePlus60Minutes.setMinutes(isoDateTimePlus60Minutes.getMinutes() + 60);
    //       }
        
    //       try {
    //         const attendees = dataEmails;
        
    //         // Создание первого мероприятия
    //         const response1 = await window.gapi.client.calendar.events.insert({
    //           calendarId: calendarId,
    //           resource: {
    //             summary: eventTitle || 'Google Meet Conference',
    //             start: {
    //               dateTime: isoDateTime.toISOString(),
    //               timeZone: 'Europe/Moscow',
    //             },
    //             end: {
    //               dateTime: isoDateTime.toISOString(),
    //               timeZone: 'Europe/Moscow',
    //             },
    //             conferenceData: {
    //               createRequest: {
    //                 requestId: 'YOUR_REQUEST_ID',
    //               },
    //             },
    //             visibility: eventType === 'open' ? 'public' : 'private',
    //             attendees: attendees.map(email => ({ email })),
    //           },
    //           conferenceDataVersion: 1,
    //         });
        
    //         const createdMeetingLink1 = response1.result.hangoutLink;
    //         addWebinarCalendar(createdMeetingLink1, eventTitle);
    //         setMeetingLink(createdMeetingLink1)
    //         // Создание второго мероприятия
    //         if (eventTime) {
    //           const response2 = await window.gapi.client.calendar.events.insert({
    //             calendarId: calendarId,
    //             resource: {
    //               summary: eventTitle || 'Google Meet Conference',
    //               start: {
    //                 dateTime: isoDateTimePlus60Minutes.toISOString(),
    //                 timeZone: 'Europe/Moscow',
    //               },
    //               end: {
    //                 dateTime: isoDateTimePlus60Minutes.toISOString(),
    //                 timeZone: 'Europe/Moscow',
    //               },
    //               conferenceData: {
    //                 createRequest: {
    //                   requestId: 'YOUR_REQUEST_ID',
    //                 },
    //               },
    //               visibility: eventType === 'open' ? 'public' : 'private',
    //               attendees: attendees.map(email => ({ email })),
    //             },
    //             conferenceDataVersion: 1,
    //           });
        
    //           const createdMeetingLink2 = response2.result.hangoutLink;
    //           addWebinarCalendar(createdMeetingLink2, eventTitle);
    //           console.log('Second meeting created:', createdMeetingLink2);
    //         }
           
        
    //         // // Отправка письма со ссылкой на конференцию
    //         // const message = [
    //         //   'Content-Type: text/plain; charset="UTF-8"\n',
    //         //   'MIME-Version: 1.0\n',
    //         //   'Content-Transfer-Encoding: 7bit\n',
    //         //   `to: ${dataEmails.join(',')}\n`,
    //         //   'subject: Вебинар\n\n',
    //         //   `Ссылка на конференцию: ${createdMeetingLink1}`,
    //         // ].join('');
        
    //         // const encodedMessage = btoa(message)
    //         //   .replace(/\+/g, '-')
    //         //   .replace(/\//g, '_');
        
    //         // await gapi.client.gmail.users.messages.send({
    //         //   userId: 'me',
    //         //   resource: {
    //         //     raw: encodedMessage,
    //         //   },
    //         // });
        
    //         console.log('First meeting created:', createdMeetingLink1);
    //       } catch (error) {
    //         console.error('Error creating meeting:', error);
    //       }
    // };


    const createMeetingMail = async () => {
        if (!startTime) {
          console.error('Start time is required');
          return;
        }
      
        const isoDateTime = new Date(startTime);
        const isoDateTimePlus60Minutes = new Date(startTime);
      
        if (eventTime) {
          isoDateTimePlus60Minutes.setMinutes(isoDateTimePlus60Minutes.getMinutes() + 60);
        }
      
        try {
          const attendees = dataEmails;
      
          await gapi.client.init({
            clientId: clientId,
            scope: 'https://www.googleapis.com/auth/calendar',
          });
      
          await gapi.auth2.getAuthInstance().signIn();
      
          const calendar = gapi.client.calendar;
      
          const createEvent = async (eventDateTime, eventTitle) => {
            const event = {
              summary: eventTitle || 'Google Meet Conference',
              start: {
                dateTime: eventDateTime.toISOString(),
                timeZone: 'Europe/Moscow',
              },
              end: {
                dateTime: eventDateTime.toISOString(),
                timeZone: 'Europe/Moscow',
              },
              conferenceData: {
                createRequest: {
                  requestId: 'YOUR_REQUEST_ID',
                },
              },
              visibility: eventType === 'open' ? 'public' : 'private',
              attendees: attendees.map(email => ({ email })),
            };
      
            const response = await calendar.events.insert({
              calendarId: 'primary',
              resource: event,
              conferenceDataVersion: 1,
            });
      
            const createdMeetingLink = response.result.hangoutLink;
            addWebinarCalendar(createdMeetingLink, eventTitle);
            setMeetingLink(createdMeetingLink);
            console.log('Meeting created:', createdMeetingLink);
          };
      
          // Создание первого мероприятия
          await createEvent(isoDateTime, eventTitle);
      
          // Создание второго мероприятия
          if (eventTime) {
            await createEvent(isoDateTimePlus60Minutes, eventTitle);
          }
      
          console.log('Meetings created successfully');
        } catch (error) {
          console.error('Error creating meetings:', error);
        }
      };
      

    useEffect(() => {
        axios.get('https://reps-club.com:8000/v1/mounth/events')
            .then(response => {
                setDataEvents(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        axios.get('https://reps-club.com:8000/v1/webibar/mail', {
            headers: {
                'x-access-token': localStorage.getItem("accessToken")
            }
        })
            .then(response => {
                setDataEmails(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);


    const updateEvents = () => {
        axios.get('https://reps-club.com:8000/v1/mounth/events')
            .then(response => {
                setDataEvents(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }


    const delEvent = (id) => {
        if (localStorage.getItem('accessToken') !== null) {
            axios.delete(`https://reps-club.com:8000/v1/del/event/${id}`, {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then(function (res) {
                toaster.success('Событие календаря удалено!', {
                    id: 'forbidden-action',
                })
                updateEvents();

            })
        } else {
            toaster.danger('Ошибка! Попробуйте обновить страницу', {
                id: 'forbidden-action',
            })
        }
    }

    return (

        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Управление вебинаром</p>
                            </div>
                            <div className='form-create-webinar'>
                                {isLoaded ? (
                                    <div className='form-webinar-content'>
                                        <TextInputField
                                            label="Название вебинара"
                                            value={eventTitle}
                                            onChange={handleEventTitleChange}
                                            inputHeight={48}
                                            marginBottom={16}
                                            placeholder="Название"
                                        />
                                        <Select
                                            label="Тип вебинара"
                                            value={eventType}
                                            onChange={handleEventTypeChange}
                                            marginBottom={16}
                                            height={48}
                                            width={'100%'}
                                        >
                                            <option value="open">Открытый (доступен всем)</option>
                                            <option value="closed">Закрытый (доступен только подписчикам)</option>
                                        </Select>
                                        <Select
                                            label="Время вебинара"
                                            onChange={handleEventTimeChange}
                                            marginBottom={16}
                                            height={48}
                                            width={'100%'}
                                        >
                                            <option value="1">60 минут</option>
                                            <option value="2">120 минут</option>
                                        </Select>
                                        <TextInputField
                                            type="datetime-local"
                                            label="Когда начнется?"
                                            value={startTime}
                                            onChange={handleStartTimeChange}
                                            inputHeight={48}
                                            marginBottom={16}
                                        />
                                        <Button
                                            width={'100%'}
                                            height={48}
                                            appearance='primary'
                                            onClick={eventType === false  ? createMeeting : createMeetingMail}
                                        >
                                            Создать Google Meet Conference
                                        </Button>
                                        {meetingLink && (
                                            <Paragraph>
                                                Meeting created: <a href={meetingLink} target="_blank" rel="noopener noreferrer">{meetingLink}</a>
                                            </Paragraph>
                                        )}
                                    </div>
                                ) : (
                                    <p>Loading Google API...</p>
                                )}
                            </div>
                        </section>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Редактор событий</p>
                            </div>
                            <div className='lk-list-event'>
                                <div>
                                    <Table>
                                        <Table.Head>
                                            <Table.TextHeaderCell>Что за мероприятие?</Table.TextHeaderCell>
                                            <Table.TextHeaderCell>Тип мероприятия</Table.TextHeaderCell>
                                            <Table.TextHeaderCell>Ссылка</Table.TextHeaderCell>
                                            <Table.TextHeaderCell>Управление</Table.TextHeaderCell>
                                        </Table.Head>
                                        <Table.Body height={240}>
                                            {dataEvents.filter((event) => event.typeEvent === 3).reverse().map((event) => (
                                                <Table.Row key={event.id}>
                                                    <Table.TextCell>{event.description}</Table.TextCell>
                                                    <Table.TextCell> <Badge color={event.typeEvent === 1 ? "green" : event.typeEvent === 2 ? "red" : event.typeEvent === 3 ? "yellow" : event.typeEvent === 4 ? "blue" : "neutral"} marginRight={8}>{event.type_event.nameEvent}</Badge></Table.TextCell>
                                                    <Table.TextCell >{event.typeEvent === 3 ? (<a href={event.linkEvent} target='_blank' rel="noopener noreferrer">{event.linkEvent}</a>) : 'Ссылка скрыта'}</Table.TextCell>
                                                    <Table.TextCell><span className='table-btn' onClick={() => delEvent(event.id)} ><TrashCan strokeWidth={2} size={24} /></span></Table.TextCell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </div>



    );
};

export default GoogleMeetConference;

