import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/header";
import "../styles/flexboxgrid.min.css";

import { Avatar } from "evergreen-ui";

import Verified from "../content/img/Verified.svg";

function CabinetPage(props) {
    const [arrUsers, setUsers] = useState([]);

    useEffect(() => {
        axios.get(`https://reps-club.com:8000/v1/user/all`).then(function (res) {
         /*   const sortedUsers = res.data.sort((a, b) => {
                if (a?.user?.verified && !b?.user?.verified) {
                    return -1;
                } else if (!a?.user?.verified && b?.user?.verified) {
                    return 1;
                } else {
                    return 0;
                }
            });

            const usersWithActiveTab = sortedUsers.map((user) => ({
                ...user,
                activeTab: 0, // Set the first tab as active for each user
            }));

            setUsers(usersWithActiveTab);
        });*/

         const filteredUsers = res.data.filter(user => user.user !== null && user.fio.trim() !== '' &&
            user.user.verified === true);

        const sortedUsers = filteredUsers.sort((a, b) => {
            if (a?.user?.verified && !b?.user?.verified) {
                return -1;
            } else if (!a?.user?.verified && b?.user?.verified) {
                return 1;
            } else {
                return 0;
            }
        });

        const usersWithActiveTab = sortedUsers.map((user) => ({
            ...user,
            activeTab: 0, // Set the first tab as active for each user
        }));

        setUsers(usersWithActiveTab);
  });
    }, []);

    function addHttpsPrefix(url) {
        if (!url.startsWith("https://")) {
            return "https://" + url;
        }

        return url;
    }

    const handleTabClick = (index, userIndex) => {
        setUsers((prevUsers) => {
            const updatedUsers = [...prevUsers];
            updatedUsers[userIndex].activeTab = index;
            return updatedUsers;
        });
    };

    return (
        <div className="App">
            <Header />
            <main className="content-profiles">
                <div className="container-fluid">
                    <div className="row-flex profiles-grid">
                        {arrUsers.map((user, userIndex) => (
                            <div
                                className=" col-xs-12 col-sm-6 col-md-6 col-lg-6"
                                key={userIndex}
                            >
                                <div className="profile-card-i">
                                    <div className="profile-card-header">
                                        <div className="profile-card-user">
                                            <div className="profile-user-verified">
                                                <Avatar
                                                    name={user?.fio}
                                                    size={56}
                                                    color="blue"
                                                    src={user?.photo}
                                                />
                                                {user?.user?.verified && (
                                                    <div className="icon-verified">
                                                        <img
                                                            src={Verified}
                                                            alt="Подтвержденный аккаунт"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <p className="profile-user-fio">
                                                {user?.fio}
                                                <span>Участник клуба</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="profile-card-content">
                                        <div className="profile-card-tab-menu">
                                            <div
                                                className={`tab ${user.activeTab === 0 ? "active-tab" : ""
                                                    }`}
                                                onClick={() => handleTabClick(0, userIndex)}
                                            >
                                                Информация об участнике
                                            </div>
                                            <div
                                                className={`tab ${user.activeTab === 1 ? "active-tab" : ""
                                                    }`}
                                                onClick={() => handleTabClick(1, userIndex)}
                                            >
                                                Мои проекты
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            {user.activeTab === 0 && (
                                                <div>
                                                    <ul className="user-list">
                                                        <li>
                                                            <span className="list-item">Город:</span>
                                                            <span className="list-value">
                                                                {
                                                                     user?.myCity.length > 1 ?
                                                                     user?.myCity +'('+user?.myCountry+')'
                                                                    : 'Не указан'
                                                                }
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="list-item">Сайт:</span>
                                                            <span className="list-value">
                                                                <a
                                                                    href={
                                                                        user?.siteLink.length > 3
                                                                            ? addHttpsPrefix(user?.siteLink.replace('http://', ''))
                                                                            : "#"
                                                                    }
                                                                >
                                                                    { user?.siteLink.length > 3
                                                                        ? addHttpsPrefix(user?.siteLink.replace('http://', ''))
                                                                        : "Не указан"}
                                                                </a>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="list-item">Telegram:</span>
                                                            <span className="list-value">
                                                            { user?.telegram?.length > 3 && user?.telegram !== 'отсутствует' 
                                                                        ? '@'+user?.telegram?.toString().replace("@", "")
                                                                        : "Не указан"}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <p
                                                        style={{
                                                            borderTop: "1.5px solid #000",
                                                            paddingTop: "8px",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight: "600",
                                                                display: "block",
                                                                marginBottom: "8px",
                                                            }}
                                                        >
                                                            Обо мне:
                                                        </span>
                                                        {user?.myAbout?.length >= 3 ?
                                                        user?.myAbout
                                                        :
                                                        'Участник еще не указал ифнормацию о себе'
                                                    }
                                                    </p>
                                                </div>
                                            )}
                                            {user.activeTab === 1 && (
                                                <div className="user-list-project">
                                                    {user?.projects && user?.projects.length > 0 ? (
                                                        user?.projects.map((project, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        marginBottom: "8px",
                                                                    }}
                                                                >
                                                                    {project?.linkMedia.slice(0, 1).map(
                                                                        (link, index) => (
                                                                            <img
                                                                                src={link}
                                                                                alt={"Фотография " + index + " проекта"}
                                                                                loading="lazy"
                                                                                key={link}
                                                                                style={{
                                                                                    width: "64px",
                                                                                    height: "64px",
                                                                                    borderRadius: "100px",
                                                                                    objectFit: "cover",
                                                                                    marginRight: "8px",
                                                                                }}
                                                                            />
                                                                        )
                                                                    )}
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{
                                                                            color: "#000",
                                                                            textDecoration: "none",
                                                                            borderBottom: "1.5px dashed #000",
                                                                        }}
                                                                        href={"/project/" + project?.idProject}
                                                                    >
                                                                        {project?.nameProject}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>Участник не добавил свои проекты</p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>
        </div>
    );
}

export default CabinetPage;
