import React, { useEffect, useState } from 'react';
import { Pane, TextInputField, FileUploader, Textarea, Button, toaster, TagInput } from 'evergreen-ui';
import { TrashCan, Cross } from 'akar-icons'
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from 'axios';
import '../styles/main.css';
import '../styles/lk.css';
import '../styles/flexboxgrid.min.css';
import HeaderLeft from '../components/headerLeft'
import imgWelcomePortfolio from '../content/img/project-img.svg'



function PortfolioPage(props) {
    const data = props.data;
    const profile = props.profile;
    const [selectedImages, setSelectedImages] = useState([]);
    const [values, setValues] = useState([])
    const [valuesFurniture, setValuesFurniture] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [isNotify, setIsNotify] = useState(false);

    const [arrProject, setProject] = useState([]);
    const [arrMedia, setMedia] = useState([]);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const handleOverlayClick = () => {

        toggleModal(false);

    };

    const handleImageChange = (images) => {
        setSelectedImages(images);
    };
    const [projectName, setProjectName] = useState('');
    const [fioDesigner, setFioDesigner] = useState('');
    const [linkDesigner, setLinkDesinger] = useState('');
    const [projectDescription, setProjectDescription] = useState('');

    const handleDeleteFile = (index) => {
        const updatedFiles = [...selectedImages];
        updatedFiles.splice(index, 1);
        setSelectedImages(updatedFiles);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        selectedImages.forEach((image) => {
            formData.append('images', image);
        });

        
        formData.append('name', projectName);
        formData.append('description', projectDescription);
        formData.append('fioDesigner', fioDesigner);
        formData.append('linkDesigner', linkDesigner);
        formData.append('tkani', values.toString().split(','));
        formData.append('furnitura', valuesFurniture.toString().split(','));

        axios.post('https://reps-club.com:8000/v1/user/project', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': localStorage.getItem("accessToken")
            }
        })
            .then((response) => {
                setIsOpen(false);
                setSelectedImages([]);
                setValues([]);
                setValuesFurniture([]);
                setProjectName('');
                setFioDesigner('');
                setLinkDesinger('');
                setProjectDescription('');
                updateProject();
                toaster.success('Ура 🥳 Вы добавили новый проект!', {
                    id: 'forbidden-action',
                })
            })
            .catch((error) => {
                toaster.danger('Что-то пошло не так, попробуйте обновить страницу', {
                    id: 'forbidden-action',
                })
            });
    };

    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            axios.get('https://reps-club.com:8000/v1/user/get/project', {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then(function (res) {
                if (res.data.length === 0) {

                    setIsNotify(true);
                } else {
                    setProject(res.data);
                    setIsNotify(false);
                }

            })
        } else {
            toaster.danger('Ошибка получения данных, попробуйте обновить страницу', {
                id: 'forbidden-action',
            })
            setMedia([]);
            setProject([]);
        }
    }, []);

    const updateProject = async () => {
        if (localStorage.getItem('accessToken') !== null) {
            await axios.get('https://reps-club.com:8000/v1/user/get/project', {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then(function (res) {
                if (res.data.length === 0) {
                    setIsNotify(true);
                } else {
                    setProject(res.data);
                    setIsNotify(false);
                }

            })
        } else {
            toaster.danger('Ошибка получения данных, попробуйте обновить страницу', {
                id: 'forbidden-action',
            })
            setMedia([]);
            setProject([]);
        }
    }

    const deleteProject = async (id) => {
        if (localStorage.getItem('accessToken') !== null) {
            await axios.delete(`https://reps-club.com:8000/v1/user/delete/project/${id}`, {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then(function (res) {
                toaster.success('Проект успешно удален!', {
                    id: 'forbidden-action',
                })
                updateProject();

            })
        } else {
            toaster.danger('Ошибка! Попробуйте обновить страницу', {
                id: 'forbidden-action',
            })
        }
    }

    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Мое портфолио</p>
                            </div>
                            <div className='lk-portfolio-content'>
                                <div className='lk-portfolio-functional'>
                                    <button onClick={toggleModal}>Добавить новый проект</button>
                                </div>
                                {isOpen && (
                                    <div className="overlay-2" onClick={handleOverlayClick}>
                                        <div className="modal-2" onClick={e => e.stopPropagation()}>
                                            <div className="modal-content-2">

                                                <form onSubmit={handleSubmit} >
                                                    <img src={imgWelcomePortfolio} alt='Новый проект' />
                                                    <Pane>

                                                        <TextInputField
                                                            label="Название проекта"
                                                            value={projectName}
                                                            onChange={(event) => setProjectName(event.target.value)}
                                                            marginBottom={16}
                                                            name="name"
                                                            inputHeight={48}
                                                            placeholder="Ваше название проекта"
                                                        />

                                                        <Textarea
                                                            label="Описание проекта"
                                                            value={projectDescription}
                                                            onChange={(event) => setProjectDescription(event.target.value.replace('/\n/g', '\n'))}
                                                            marginBottom={16}
                                                            name='description'
                                                            style={{ fontSize: 16, padding: '16px 16px', lineHeight: '24px' }}
                                                            placeholder="Опишите свой проект"
                                                            wrap='soft'

                                                        />
                                                        <div className='form-line'>
                                                            <TextInputField
                                                                label="Дизайнер или архитектор?"
                                                                marginBottom={16}
                                                                name="designer"
                                                                inputHeight={48}
                                                                placeholder="Укажите его Имя и Отчество"
                                                                value={fioDesigner}
                                                                onChange={(event) => setFioDesigner(event.target.value)}
                                                            />
                                                            <TextInputField
                                                                label="Ссылка на него"
                                                                marginBottom={16}
                                                                name="name"
                                                                inputHeight={48}
                                                                placeholder="Укажите ссылку на него"
                                                                value={linkDesigner}
                                                                onChange={(event) => setLinkDesinger(event.target.value)}
                                                            />
                                                        </div>
                                                        <TagInput
                                                            className='TagInput'
                                                            inputProps={{ placeholder: 'Производитель тканей...' }}
                                                            values={values}
                                                            tagSubmitKey="enter"
                                                            height={48}
                                                            width={'100%'}
                                                            id='tkani'
                                                            marginBottom={16}
                                                            onChange={(values) => {
                                                                setValues(values)
                                                            }}
                                                        />
                                                        <TagInput
                                                            className='TagInput'
                                                            inputProps={{ placeholder: 'Производитель фурнитуры...' }}
                                                            values={valuesFurniture}
                                                            tagSubmitKey="enter"
                                                            height={48}
                                                            id='furnitura'
                                                            width={'100%'}
                                                            marginBottom={16}
                                                            onChange={(values) => {
                                                                setValuesFurniture(values)
                                                            }}
                                                        />


                                                        <FileUploader
                                                            multiple
                                                            onChange={handleImageChange}
                                                            placeholder="Выберите изображения"
                                                            marginBottom={16}
                                                            accept={["image/png", "image/jpg", "image/jpeg", "image/webp"]}
                                                            name="images"
                                                            height={200}

                                                        />
                                                        {selectedImages.map((file, index) => (
                                                            <div className='file-item'>

                                                                <p>{file.name}<span> <TrashCan strokeWidth={2} size={16} onClick={() => handleDeleteFile(index)} /></span></p>


                                                            </div>
                                                        ))}
                                                    </Pane>
                                                    <Button type="submit" height={48} appearance="primary">Добавить проект</Button>
                                                    <Button type="submit" height={48} marginLeft={16} appearance="default" onClick={() => setIsOpen(false)}>Не хочу добавлять</Button>


                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                )}

                     

                                {
                                    isNotify ? (
                                        <div className='none-project'>
                                            <p>У тебя еще нет проектов, не беда, скорее добавь его! ☺️</p>
                                        </div>
                                    ) :
                                        <div className='grid-pictures row'>
                                            {arrProject.map((project, index) => (
                                                <LazyLoad key={index} height={200} offset={100} once className='col-md-4' style={{ padding: '2px' }}>
                                                    <picture className="lk-card_picture">
                                                        <div className='lk-card_picture-edit'>
                                                            <p onClick={() => deleteProject(project.idProject)}><Cross strokeWidth={3} size={16} /></p>
                                                        </div>
                                                        <p className="lk-card_picture-link" onClick={() => window.location.replace('/project/' + project.idProject)}>
                                                            {project.media.length > 0 && (
                                                                <LazyLoadImage
                                                                    alt={"Фотография " + project.media[0].linkMedia[0] + " проекта"}
                                                                    effect="blur"
                                                                    src={project.media[0].linkMedia[0]}
                                                                    key={project.media[0].linkMedia[0]}
                                                                    width={'100%'}
                                                                    height={'100%'}
                                                                />
                                                            )}
                                                        </p>
                                                        <Link to={'/project/' + project.idProject}>
                                                            <p className='lk-card-name-project'>
                                                                {project.nameProject}
                                                            </p>
                                                        </Link>
                                                    </picture>
                                                </LazyLoad>
                                            ))}
                                        </div>
                                }
                            </div>
                        </section>

                    </main>
                </div>
            </div>
        </div>
    )
}
export default PortfolioPage