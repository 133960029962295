import React from 'react';
import '../styles/main.css'
const Loading = () => {
  return (
    <div className="loading-svg">
      <svg width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
        <g strokeWidth="1" strokeLinecap="round">
          <path stroke="#fff" d="M11 1v3" />
          <path stroke="#000" d="M16 2.34l-1.5 2.6" />
          <path stroke="#171717" d="M19.66 6l-2.6 1.5" />
          <path stroke="#2e2e2e" d="M18 11h3" />
          <path stroke="#454545" d="M17.06 14.5l2.6 1.5" />
          <path stroke="#5c5c5c" d="M14.5 17.06l1.5 2.6" />
          <path stroke="#737373" d="M11 18v3" />
          <path stroke="#8d8d8d" d="M7.5 17.06L6 19.66" />
          <path stroke="#a2a2a2" d="M4.94 14.5L2.34 16" />
          <path stroke="#b9b9b9" d="M1 11h3" />
          <path stroke="#d0d0d0" d="M2.34 6l2.6 1.5" />
          <path stroke="#e7e7e7" d="M6 2.34l1.5 2.6" />
        </g>
      </svg>
    </div>
  )
};

export default Loading;