import React, { useState, useEffect } from 'react';
import HeaderLeft from '../../components/headerLeft'
import { Checkbox, Dialog, TextInput, Textarea, Button, Avatar, TextInputField, Pane, toaster } from 'evergreen-ui';
import axios from 'axios'

const AdminPanel = (props) => {

    const data = props.data;
    const profile = props.profile;

    const [users, setUsers] = useState([]);
    const [isCreate, setCreate] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        description: '',
        endSubscribe: '',
        startSubscribe: '',
        verified: false
    });
    const [isDialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        // Получаем список пользователей при загрузке компонента
        fetch('https://reps-club.com:8000/v1/admin/users')
            .then(response => response.json())
            .then(data => setUsers(data))
            .catch(error => console.error(error));
    }, []);

    const handleEditUser = (user) => {
        // Устанавливаем пользователя для редактирования и заполняем форму данными пользователя
        setEditingUser(user);
        setFormData({
            username: user.username,
            email: user.email,
            phone: user.phone,
            description: user.description,
            endSubscribe: user.end_subscribe,
            startSubscribe: user.start_subscribe,
            verified: user.verified
        });
        setDialogOpen(true);
    };

    const handleInputChange = (e) => {
        // Обновляем данные формы при изменении полей
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { username, email, phone, description, endSubscribe, startSubscribe, verified } = formData;

        // Отправляем запрос на редактирование пользователя
        fetch(`https://reps-club.com:8000/v1/admin/edit/users/${editingUser.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, email, phone, description, endSubscribe, startSubscribe, verified })
        })
            .then(response => response.json())
            .then(data => {
                // Обновляем список пользователей и очищаем форму
                setUsers(users.map(user => user.id === data.id ? data : user));
                setFormData({
                    username: '',
                    email: '',
                    phone: '',
                    description: '',
                    endSubscribe: '',
                    startSubscribe: '',
                    verified: false
                });
                toaster.success('Данные пользователя обновлены', {
                    id: 'forbidden-action',
                })
                setEditingUser(null);
                setDialogOpen(false);
            })
            .catch(error => console.error(error));
    };


    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');
    const [password, setPassword] = useState('');
    const [endSubscribe, setEndSubscribe] = useState('');
    const [startSubscribe, setStartSubscribe] = useState('');
    const [verified, setVerified] = useState(false);

    const handleSubmit2 = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://reps-club.com:8000/v1/admin/create/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    email,
                    phone,
                    description,
                    password,
                    endSubscribe,
                    startSubscribe,
                    verified
                })
            });

            if (response.ok) {
                const user = await response.json();
                toaster.success('Пользователь был успешно создан', {
                    id: 'forbidden-action',
                })
            } else {
                console.error('Failed to create user');
            }
        } catch (error) {
            console.error('Failed to create user:', error);
        }
    };

    const deleteUser = (id) => {
        if (localStorage.getItem('accessToken') !== null) {
            axios.delete(`https://reps-club.com:8000/v1/admin/del/user/${id}`, {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then(function (res) {
                toaster.success('Пользователь удален!', {
                    id: 'forbidden-action',
                })

            })
        } else {
            toaster.danger('Ошибка! Попробуйте обновить страницу', {
                id: 'forbidden-action',
            })
        }
    }


    return (
        <div className="container-lk">
            <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                <HeaderLeft user={data} profileInfo={profile} />
                <main>
                    <section className='lk-section-default'>
                        <div className='lk-section-title'>
                            <p>Управление пользователями</p>
                        </div>
                        <div style={{ width: '100%', borderBottom: '1.5px solid #000', padding: '32px 32px' }}>
                            {!isCreate ?
                                <Button height={48} onClick={() => setCreate(true)} appearance='primary'>Добавить новый аккаунт</Button>
                                :
                                <Button height={48} onClick={() => setCreate(false)} intent="none">Отменить добавление</Button>
                            }
                            {
                                isCreate &&
                                <form onSubmit={handleSubmit2} style={{ marginTop: '32px' }}>
                                    <Pane style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <TextInputField
                                            width={'100%'}
                                            label="Логин"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            inputHeight={48}
                                        />

                                        <TextInputField
                                            width={'100%'}
                                            marginLeft={16}
                                            label="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            inputHeight={48}
                                        />
                                    </Pane>
                                    <Pane style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <TextInputField
                                            label="Телефон"
                                            value={phone}
                                            width={'100%'}

                                            onChange={(e) => setPhone(e.target.value)}
                                            inputHeight={48}
                                        />

                                        <TextInputField
                                            width={'100%'}
                                            marginLeft={16}
                                            label="Описание"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            inputHeight={48}
                                        />
                                    </Pane>

                                    <TextInputField
                                        width={'100%'}

                                        label="Пароль"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        inputHeight={48}
                                    />
                                    <Pane style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <TextInputField
                                            width={'100%'}
                                            type='date'
                                            label="Дата начала подписки"
                                            value={startSubscribe}
                                            onChange={(e) => setStartSubscribe(e.target.value)}
                                            inputHeight={48}
                                        />

                                        <TextInputField
                                            width={'100%'}
                                            type='date'
                                            marginLeft={16}
                                            label="Дата окончания подписки"
                                            value={endSubscribe}
                                            onChange={(e) => setEndSubscribe(e.target.value)}
                                            inputHeight={48}
                                        />
                                    </Pane>



                                    <Button type="submit" appearance='primary' height={48}>Создать пользователя</Button>

                                </form>

                            }
                        </div>
                        <div className='lk-setting-anketa'>

                            <div className='row user_admin-cards'>
                                {users.map(user => (
                                    <div className='col-md-4 user_admin-item-card' key={user.id}>
                                        <div className='user_admin-content'>
                                            {user.user_profile?.fio === null ?
                                                < Avatar name={user.user_profile?.fio} size={56} /> :
                                                <Avatar src={user.user_profile?.photo} name={user.user_profile?.fio} size={56} />
                                            }
                                            <h3>{user.username}</h3>
                                            <p>Почта: {user.email}</p>
                                            <p>Телефон: {user.phone}</p>
                                            <p>Подписка: c {user.start_subscribe} по {user.end_subscribe}</p>
                                            <Button marginTop={8} width={'100%'} onClick={() => handleEditUser(user)}>Редактировать</Button>
                                            <Button  marginTop={8} width={'100%'} onClick={()=> deleteUser(user.id)}>Удалить</Button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <Dialog
                                isShown={isDialogOpen}
                                title="Редактирование пользователя"
                                onCloseComplete={() => setDialogOpen(false)}
                                hasFooter={false}
                            >
                                <form onSubmit={handleSubmit}>
                                    <p>Имя пользователя</p>
                                    <TextInput
                                        width={'100%'}
                                        name="username"
                                        placeholder="Имя пользователя"
                                        value={formData.username}
                                        onChange={handleInputChange}
                                        required
                                        marginBottom={4}
                                    />
                                    <p>Почта</p>
                                    <TextInput
                                        width={'100%'}
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                        marginBottom={4}
                                    />
                                    <p>Телефон</p>
                                    <TextInput
                                        width={'100%'}
                                        name="phone"
                                        placeholder="Телефон"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        marginBottom={4}
                                    />
                                    <p>Описание пользователя</p>
                                    <Textarea
                                        width={'100%'}
                                        name="description"
                                        placeholder="Описание"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        marginBottom={4}
                                    />
                                    <p>Подписка с</p>
                                    <TextInput
                                        width={'100%'}
                                        type='date'
                                        name="startSubscribe"
                                        placeholder="Дата начала подписки"
                                        value={formData.startSubscribe}
                                        onChange={handleInputChange}
                                        marginBottom={4}
                                    />
                                    <p>Подписка до</p>
                                    <TextInput
                                        width={'100%'}
                                        type='date'
                                        name="endSubscribe"
                                        placeholder="Дата окончания подписки"
                                        value={formData.endSubscribe}
                                        onChange={handleInputChange}
                                        marginBottom={4}
                                    />
                                    <hr />
                                    <Checkbox
                                        type="checkbox"
                                        name="verified"
                                        label="Галочка подтверждения пользователя"
                                        checked={formData.verified}
                                        marginBottom={4}
                                        onChange={(e) => setFormData({ ...formData, verified: e.target.checked })}
                                    />
                                    <hr />
                                    <Button type="submit" marginTop={16} marginBottom={16} appearance="primary" height={48}>Сохранить</Button>
                                </form>
                            </Dialog>
                        </div>
                    </section>
                </main >

            </div >
        </div >
    );
};

export default AdminPanel;
