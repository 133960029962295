import React, { useState, useEffect } from 'react';

import '../../styles/main.css';
import '../../styles/lk.css';
import '../../styles/flexboxgrid.min.css';
import HeaderLeft from '../../components/headerLeft'
import axios from 'axios';
import { Badge, Pane, toaster, TextInputField, Button } from 'evergreen-ui';
import { TrashCan } from 'akar-icons';


function SupportMailPage(props) {

    const data = props.data;
    const profile = props.profile;

    const [dataQuestion, setDataQuestion] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [idRecipient, setRecipient] = useState(0)

    const handleOpenForm = (id) => {
        setShowForm(true);
        setRecipient(id);
        console.log(id);
    };


    useEffect(() => {
        axios.get('https://reps-club.com:8000/v1/question',
            {
                headers:
                {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            })
            .then(response => {
                setDataQuestion(response.data);
            })
            .catch(error => {

            });
    }, []);

    const handleRemoveMessage = (id) => {

        axios.delete(`https://reps-club.com:8000/v1/question/delete/${id}`, {
            headers: {
                'x-access-token': localStorage.getItem("accessToken")
            }
        }).then((response) => {


            toaster.success('Вы успешно удалили письмо!', {
                id: 'forbidden-action',
            });

        })
            .catch((error) => {
                console.log(error, 'error')
            });


    };


    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(message, idRecipient)
        try {
            const response = await axios.post('https://reps-club.com:8000/v1/admin/sendmail', {
                textMail: message,
                typeMail: 1,
                idRecipient: idRecipient
            }, {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            });

            console.log('Message sent successfully:', response.data);
            setMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };


    function truncateText(text) {
        if (text.length > 30) {
            return text.slice(0, 30) + "...";
        } else {
            return text;
        }
    }

    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Почта поддержки</p>
                            </div>
                            <div className='lk-admin-support-content'>
                                <div className='lk-admin-mail-support'>
                                    <div className='lk-notify-list'>
                                        {
                                            dataQuestion.map(item => (
                                                <div className='item-notify' key={item.id}>
                                                    <div className='type-notify'>
                                                        <Pane>
                                                            <Badge display="inline-flex" color="yellow">
                                                                Support
                                                            </Badge>
                                                        </Pane>
                                                    </div>
                                                    <div className='item-notify-questioner'>
                                                        <p><span>Отправитель:</span>{item.user ? item.user.username : 'Неизвестный отправитель'}</p>
                                                    </div>
                                                    <div className='text-notify'>
                                                        <p>{truncateText(item.description)}</p>
                                                    </div>
                                                    <div className='item-notify-send'>
                                                        <a onClick={() => handleOpenForm(item.user.id)}>На почту профиля</a>
                                                        {
                                                            item.user && item.user.phone !== null &&
                                                            (
                                                                <a href={'https://t.me/+' + item.user.phone}>Написать в телеграм</a>
                                                            )
                                                        }
                                                        {
                                                            item.user && item.user.phone !== null &&
                                                            (
                                                                <a href={'tel:+' + item.user.phone}>Позвонить</a>
                                                            )
                                                        }
                                                        <p style={{display:'flex', alignItems: 'center', justifyContent: 'center', width: '56px'}}><TrashCan strokeWidth={2} size={18} onClick={() => handleRemoveMessage(item.id)} /></p>
                                                    </div>
                                                </div>
                                            ))

                                        }
                                    </div>
                                    {showForm && <form onSubmit={handleSubmit}>
                                        <Pane width="100%" padding={32}>
                                            <TextInputField
                                                marginBottom={8}
                                                width="100%"
                                                label="Написать письмо"
                                                value={message}
                                                inputHeight={'56px'}
                                                onChange={handleInputChange}
                                            />
                                        <Button width={'100%'} type="submit" appearance='primary'>Отправить письмо</Button>
                                        </Pane>
                                    </form>}
                                </div>
                            </div>
                        </section>


                    </main>
                </div>
            </div>
        </div>
    )
}
export default SupportMailPage