import React, { useState } from 'react';
import '../../styles/main.css';
import '../../styles/lk.css';
import '../../styles/flexboxgrid.min.css';
import HeaderLeft from '../../components/headerLeft'
import axios from 'axios';
import ReactMarkdown from 'react-markdown'
import { Textarea, Button, Label } from 'evergreen-ui'



function AdminNotifyPage(props) {

    const data = props.data;
    const profile = props.profile;


    const [message, setMessage] = useState("");

    const botApiKey = '6246505928:AAGJRRJeJS1wqjpdDHv4Py91iBNFxv2KpYA'
    const groupId = '-1001972348888';

    const handleSend = () => {
        axios.post(`https://api.telegram.org/bot${botApiKey}/sendMessage`, {
            chat_id: groupId,
            text: message
        });
        setMessage("");
    };



    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile}/>
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Отправка уведомлений</p>
                            </div>
                            <div className='lk-admin-notify-content'>
                                <div>
                                    <Label htmlFor="textarea-2" marginBottom={4} display="block">
                                        Отправьте текст в группу
                                    </Label>
                                    <div id="preview"></div>
                                    <Textarea
                                        required
                                        description="Вы можете отправить любой текст в группу телеграм"
                                        onChange={(e) => setMessage(e.target.value)} value={message}
                                        marginBottom={16}
                                        marginTop={16}
                                    />

                                    <ReactMarkdown source={message} />
                                    <Button onClick={handleSend} height={32} appearance="primary">
                                        Отправить
                                    </Button>
                                </div>


                            </div>
                        </section>

                    </main>
                </div>
            </div>
        </div>
    )
}
export default AdminNotifyPage