import React, { useState, useEffect } from 'react';
import '../styles/main.css';
import '../styles/flexboxgrid.min.css';
import Header from '../components/header';
import { Button, TextInput, toaster } from 'evergreen-ui';
import { useNavigate, redirect } from 'react-router-dom';
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPlayer from 'react-player';
import 'react-lazy-load-image-component/src/effects/blur.css';


const LoginPage = () => {

    const [openBlock, setBlock] = useState(0);
    const [groupedVideos, setGroupedVideos] = useState([]);

    useEffect(() => {
        fetch(`https://reps-club.com:8000/v1/videos/start`)
            .then(response => response.json())
            .then(data => setGroupedVideos(data))
            .catch(error => console.error('Error retrieving videos:', error));
    }, []);


    const [emailVideo, setEmailVideo] = useState('');
    const handleCreateVideo = async (title, videoId) => {
        if (emailVideo.length < 5) {
            toaster.danger('Вы не указали email', {
                zIndex: 9999,
            });
        } else {
            try {
                const response = await axios.post(`https://reps-club.com:8000/v1/create/video-payment`, {
                    email: emailVideo,
                    title: title,
                    videoId: parseInt(videoId)
                });

                const { payment_url } = response.data;

                window.open(payment_url, '_blank');
            } catch (error) {
                console.error('Error creating video:', error);
            }
        }
    };


    const [videos, setVideos] = useState([]);

    const fetchVideos = async () => {
        try {
            const response = await axios.get('https://reps-club.com:8000/v1/videos/get/', {
                params: {
                    email: emailVideo // Replace with the desired email
                }
            });
            setVideos(response.data);
            
        } catch (error) {
            console.error('Error fetching videos:', error);
        }
    };


    const downloadFile = (fileName) => {
        fetch(`https://reps-club.com:8000/${fileName}`, { // Замените '/api/download' на ваш URL-адрес API для скачивания файла
          method: 'GET',
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Создаем ссылку для скачивания файла
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
      
            // Добавляем ссылку на страницу и симулируем клик для скачивания файла
            document.body.appendChild(link);
            link.click();
      
            // Удаляем ссылку после скачивания файла
            link.parentNode.removeChild(link);
          })
          .catch((error) => {
            console.error('Ошибка при скачивании файла', error);
          });
      };


    return (
        <div className="App">
            <Header />

            <main className='content video-buy-content'>
                <div className='pre-header' style={{ marginTop: '128px', marginBottom: '24px', padding: '0 16px', display: 'flex' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => setBlock(0)}>Магазин видео уроков</div>
                    <div style={{ marginLeft: '16px', cursor: 'pointer' }} onClick={() => setBlock(2)}>Мои купленные уроки</div>
                </div>
                {openBlock === 0 &&
                    <section className='section-videos'>
                        <div className='row'>
                            {
                                groupedVideos.length > 0 ?
                                    groupedVideos.map(groupedVideo => (

                                        groupedVideo.videos.toReversed().map(video => (
                                            <div className='col-md-6' style={{ padding: '16px 16px' }}>
                                                <div className='item-popular-theme' style={{ position: 'relative', minHeight: '464px' }}>
                                                    <div className='item-popular-img' style={{ height: '256px' }}>
                                                        <LazyLoadImage
                                                            effect="blur"
                                                            src={'https://reps-club.com:8000' + video.preview}
                                                            alt={video.title}
                                                            key={video.id}
                                                            width={'100%'}
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className='item-popular-content'>
                                                        <p className='item-popular-title'>{video.title}</p>
                                                        <p className='item-popular-description' dangerouslySetInnerHTML={{ __html: `${video?.description.length > 1 ? video?.description + '...' : 'Уверены Вам понравится этот урок!'}` }}/>
                                                    </div>
                                                    <div className='item-popular-buy'>
                                                        <TextInput style={{ borderRadius: '100px' }} width={'100%'} placeholder='Ваша почта' type='email' height={48} defaultValue={emailVideo} onChange={(e) => setEmailVideo(e.target.value)} marginBottom={16} />
                                                        <p  className='popular-buy-btn' onClick={() => handleCreateVideo(video?.title, video?.id)}>Купить за {video?.price} ₽</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        )
                                    )
                                    ) : null
                            }

                        </div>
                    </section>
                }
                {openBlock === 2 &&
                    <section className='section-videos'>
                        <div style={{ border: '1px solid rgba(0,0,0,0.16)', borderRadius: '100px', display: 'flex', alignItems: 'center', padding: '16px 16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', maxWidth: '512px' }}>
                                <TextInput style={{ borderRadius: '100px' }} placeholder='Ваша почта' type='email' height={48} defaultValue={emailVideo} onChange={(e) => setEmailVideo(e.target.value)} marginBottom={0}/>
                                <Button onClick={() => fetchVideos()} style={{ borderRadius: '100px', marginLeft: '16px' }} height={48} appearance='primary'  >Получить оплаченные ролики</Button>
                            </div>
                        </div>

                        {videos.map(video => (
               

                            <div className='col-md-3' style={{ padding: '16px 16px' }}>
                                <div className='item-popular-theme' style={{ position: 'relative' }}>
                                    <div className='item-popular-img'   style={{borderRadius: '32px', overflow: 'hidden'}}>
                                        <ReactPlayer
                                            url={'https://reps-club.com:8000' + video.video.link}
                                            controls
                                            width={'100%'}
                                            height={'100%'}
                                            pip
                                        />
                                    </div>
                                    <div className='item-popular-content'>
                                        <p className='item-popular-title'>{video.video.title}</p>
                                        <p className='item-popular-description' dangerouslySetInnerHTML={{ __html: `${video.video.description.length > 1 ? video.video.description.slice(0, 172) + '...' : 'Уверены Вам понравится этот урок!'}` }}/>
                                        <p  onClick={() => downloadFile(video.video.file_link)} style={{cursor: 'pointer'}}>Скачать доп. материалы</p>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </section>
                }
            </main>
        </div >
    );
};
export default LoginPage