
import React, { useState, useEffect } from 'react';
import '../styles/main.css';
import '../styles/branch.css';
import '../styles/flexboxgrid.min.css';
import { Link } from 'react-router-dom'
import logo from '../content/img/logo-wh.svg'
import logoBl from '../content/img/logo-resp.svg'
import { LinkOut } from 'akar-icons'
import Telegram from '../content/img/telegram.svg'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import axios from 'axios';
import styled from 'styled-components';
import Modal from '../components/modalBranch';

const StyledDiv = styled.div`
@media screen and (max-width: 520px) {
    .menu .logo img {
      width: 72px;
    }
    .info-banner{
      margin: 0 auto;
      text-align: center;
    }
    .info-banner h3{
      font-size: 48px;
      margin: 0 auto;
    }
    .info-banner h2{
      font-size: 24px;
    }
    .item-faq-header {
      display: block;
      height: 100%;
    }
    .faq-title-item{
      margin-bottom: 8px;
    }
    .section-one p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
    }
  } 

  @media screen and (max-width: 412px) {
    .menu .logo img {
      width: 64px;
    }
    .info-banner{
      margin: 0 auto;
      text-align: center;
      padding: 8px 8px;
    }
    .info-banner h3{
      font-size: 40px;
      margin: 0 auto;
    }
    .info-banner h2{
      font-size: 16px;
    }
    .item-faq-header {
      display: block;
      height: 100%;
    }
    .faq-title-item{
      margin-bottom: 8px;
    }
    .section-one p {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.02em;
    }
  .header-menu-branch {
    position: fixed;
    width: 100%;
    padding: 16px 16px;
    left: 0;
    z-index: 10;
  }
  .info-banner{
    padding: 0 16px;
  }
  .section-three, .section-two {
    padding: 0 16px;
  }
  .map-information-adress a{
    width:100%;
  }
  .banner-branch-content{
    border:none;
  }
}
`;

function BranchPage(props) {


    const [blockFinished, setBlockFinished] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [landing, setLanding] = useState([]);

    const [status, setStatus] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [description, setDescription] = useState('');

    const handleBuyButtonClick = () => {
        setShowModal(true);
    };

    useEffect(() => {
        axios.get('https://reps-club.com:8000/v1/landing')
            .then(response => {
                setLanding(response?.data);
            })
            .catch(error => {
                console.log(error);
            });
        setDescription(document.querySelector('.info-banner h3').innerText);
    }, []);

    useEffect(() => {
        if (localStorage.getItem('payStatus') === null) {
            axios.get('https://reps-club.com:8000/v1/checkpayment/branch/' + localStorage.getItem('payments'))
                .then(response => {
                    setStatus(response?.data.status);
                    localStorage.setItem('payStatus', response?.data.status)
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, []);





    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY - 256;
            if (scrollPosition > 0) {
                setScrolled(true);
                setBlockFinished(true);
            } else {
                setBlockFinished(false);
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);












    return (
        <div className='App'>
            <StyledDiv>
                <Modal showModal={showModal} setShowModal={setShowModal}  description={description} />
                <div id="block" className='block-banner'>
                    <div className='banner-branch'>

                        <div className='banner-branch-content'>
                            <div id='header' className={blockFinished ? 'header-menu-branch active-header-branch' : 'header-menu-branch'}>
                                <nav className='menu'>
                                    <div className='logo'>
                                        <img alt='reps club' src={blockFinished ? logoBl : logo} />
                                    </div>
                                    <div className='default-links'>
                                        <Link to={'/'} className='branch-btn-nav solid-btn' style={{ color: blockFinished ? 'black' : 'black', border: blockFinished ? '1.5px solid black' : '1.5px solid white' }}>Основной сайт</Link>
                                        <Link to={'https://t.me/share/url?url=https://reps-club.com&text=Reps Club'} target='_blank' className='branch-btn-nav circle-btn' style={{ color: blockFinished ? 'black' : 'white', border: blockFinished ? '1.5px solid black' : '1.5px solid white' }}><LinkOut strokeWidth={1.5} size={24} /></Link>
                                    </div>
                                    <div>

                                    </div>
                                </nav>
                            </div>
                            <div className='content-banner'>
                                <div className='info-banner'>
                                    <h2>{landing.map(item => (item.preTitle))}</h2>
                                    <h3>{landing.map(item => (item.title))}</h3>
                                  
                                    <p className='date-branch' dangerouslySetInnerHTML={{ __html: `${landing.map(item => (item.dateBanner))}` }} />
                                    {
                                        localStorage.getItem('payStatus') !== 'true' ?
                                            <button className='buy-pass' onClick={handleBuyButtonClick}>Купить билет</button>
                                            :
                                            <button style={{ transform: 'scale(1)', background: 'rgba(0,0,0,0)', border: '1.5px solid #fff' }} className='buy-pass'>Вы уже купили билет 🥳</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-banner-photo' style={{ backgroundImage: `url(${landing.map(item => ('https://reps-club.com:8000'+item.bg))})` }}>

                    </div>
                </div>
                <main>
                    <section className='section-one'>
                        <p dangerouslySetInnerHTML={{ __html: `${landing.map(item => (item.welcomeText))}` }} />
                    </section>
                    <section className='section-two'>
                        <p className='branch-section-title'>На шторном бранче вы получите</p>

                        <div className='row-flex get-block' dangerouslySetInnerHTML={{ __html: `${landing.map(item => (item.arrayElements))}` }} />

                    </section>
                    <section className='section-three' id='branchInfo'>
                        <p className='branch-section-title'>Расписание Шторного бранча</p>
                        <div className='faq-block' dangerouslySetInnerHTML={{ __html: `${landing.map(item => (item.arrayDays))}` }} />
                    </section>
                </main>
                <footer>
                    <div className='footer-branch-map'>
                        <div className='row-flex'>
                            <div className='col-md-6 col-xs-12'>
                                <div className='map-information-branch'>
                                    <p className='map-information-title'>
                                        Место встречи
                                    </p>
                                    <div className='map-information-adress'>
                                        <p>Адрес</p>
                                        <a href='/' target='_blank' dangerouslySetInnerHTML={{ __html: `${landing.map(item => (item.dateBanner))}` }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-xs-12'>
                                <YMaps>
                                    <div style={{ width: "100%", height: "412px" }}  >
                                        <Map
                                            defaultState={{
                                                center: landing.map(item => (item.latitude)).length > 0 ? [landing.map(item => (item.latitude)), landing.map(item => (item.longitude))] : [55.684758, 37.738521],
                                                zoom: 16,
                                            }}
                                            width={'100%'}
                                            height={'412px'}
                                        >
                                            <Placemark geometry={landing.map(item => (item.latitude)).length > 0 ? [landing.map(item => (item.latitude)), landing.map(item => (item.longitude))] : [55.684758, 37.738521]} />
                                        </Map>
                                    </div>
                                </YMaps>
                            </div>
                        </div>
                    </div>
                    <div className='main_footer'>
                        <div className='footer-content container'>
                            <div className='row'>
                                <div className='col-md-2 col-xs-12'>
                                    <div className='footer-logo'>
                                        <img src={logo} alt='Reps Club' />
                                    </div>
                                </div>
                                <div className='col-md-4 col-xs-12'>
                                    <div className='footer-section'>
                                        <p className='footer-section-title'>По вопросам партнерства</p>
                                        <div className='footer-contact-list'>
                                            <p>Почта: <a href='mailto:atelu@mail.ru'>atelu@mail.ru</a></p>
                                            <p>Телефон: <a href="tel:+79165389155">+ 7 (916) 538 91-55</a></p>
                                            <p>Телеграм: <a href='https://t.me/Lozovskaya_atelu'>@Lozovskaya_atelu</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-xs-12'>
                                    <div className='footer-section'>
                                        <p className='footer-section-title'>Подпишись на нас</p>
                                        <div className='footer-social-media'>
                                            <a href='/#'><img src={Telegram} alt='Телеграм Лазовская Юлия' /></a>
                                            {/* <a href='/#'><img src={Instagram} alt='Инстаграм Лазовская Юлия' /></a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <p className='info-meta'>Meta признана экстремистcкой организацией в России» или «Instagram, продукт компании Meta, которая признана экстремистской организацией в России</p> */}
                        </div>
                    </div>
                    <div className='pre_footer'>
                        <p className='footer-copy'>© Reps Club, 2023</p>
                    </div>
                </footer>
            </StyledDiv>
        </div>
    )
}
export default BranchPage
