import React, { useState, useEffect } from 'react';
import '../../styles/main.css';
import '../../styles/lk.css';
import '../../styles/flexboxgrid.min.css';
import HeaderLeft from '../../components/headerLeft'
import axios from 'axios';
import { Text, Badge, Table, Pane, InfoSignIcon, Tooltip } from 'evergreen-ui';




function StatisticPage(props) {
    const data = props.data;
    const profile = props.profile;

    const [statistics, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://reps-club.com:8000/v1/statistics');
                const responseData = await response.json();
                setData(responseData);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [])


    const getStatusBadge = (status) => {
        if (status === 'succeeded') {
            return <Badge color="green" >Оплачено</Badge>;
        } else if (status === 'canceled') {
            return <Badge color="orange">Возвращен</Badge>;
        } else {
            return <Badge color="red">Не оплачен</Badge>;
        }
    };

    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Статистика</p>
                            </div>

                            <div className='lk-list-event'>
                                <div>
                                    <Pane margin={16}>
                                        <Table>
                                            <Table.Head>
                                                <Table.TextHeaderCell>Почта</Table.TextHeaderCell>
                                                <Table.TextHeaderCell>Банк</Table.TextHeaderCell>
                                                <Table.TextHeaderCell>Стоимость</Table.TextHeaderCell>
                                                <Table.TextHeaderCell>Статус</Table.TextHeaderCell>
                                                <Table.TextHeaderCell>Описание</Table.TextHeaderCell>
                                            </Table.Head>
                                            <Table.Body>
                                                {statistics.map((item) => (
                                                    <Table.Row key={item.id} style={item.paymentData?.description === "Оплата основного тарифа (подписка)(участник клуба)" ? { background: '#DCF2EA', borderTop: '1px solid rgba(0,0,0,0.24)' } : {}}                                                    >
                                                        <Table.TextCell>{item.email}</Table.TextCell>
                                                        <Table.TextCell>{item.paymentData?.payment_method?.type}</Table.TextCell>
                                                        <Table.TextCell>{item.paymentData?.amount?.value} ₽</Table.TextCell>
                                                        <Table.TextCell>{getStatusBadge(item.paymentData?.status)}</Table.TextCell>
                                                        <Table.TextCell style={{ whiteSpace: 'break-spaces' }} >

                                                            <Tooltip content={item.paymentData?.description}>
                                                                <InfoSignIcon />
                                                            </Tooltip>
                                                        </Table.TextCell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                        {statistics.length === 0 && <Text>No data found</Text>}
                                    </Pane>
                                </div>
                            </div>
                        </section>

                    </main>
                </div>
            </div>
        </div>
    )
}
export default StatisticPage