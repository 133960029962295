import React, { useState, useEffect, useRef } from 'react';
import '../styles/header.css';
import { Person, ThreeLineHorizontal } from 'akar-icons';
import logo from '../content/img/logo-resp.svg';
import { Link } from 'react-router-dom';
import { Button } from 'evergreen-ui';
import oferta from '../content/documents/oferta-repsclub.docx'



const Header = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <header>
            <nav className='menu'>
                <div className='header_menu-left'>
                    <div className='menu-logo'>
                        <a href='/'><img src={logo} alt='Resp Club' /></a>
                    </div>
                </div>
                <div className='header_menu-center'>

                </div>
                <div className='header_menu-right'>
                    <div className='menu-items'>
                        <a href='#format' className='menu_link-color'>Вступить</a>
                        <Link to='/pasport' className='menu_link-default'>Паспорта участников</Link>
                        {localStorage.getItem('accessToken') !== null ? (
                            <Link to='/lk' className='menu_link-default'><Person strokeWidth={2} size={20} /> Личный кабинет</Link>
                        ) : (
                            <Link to='/login' className='menu_link-default'>Войти</Link>
                        )
                        }
                    </div>
                    <div className='menu-burger' onClick={() => handleToggle()}>
                        <p><ThreeLineHorizontal strokeWidth={2} size={20} /> Меню</p>
                    </div>
                </div>
            </nav>
            {isOpen && (
                <>
                    <div className="burger-overlay"></div>
                    <div className="nav-burger" ref={menuRef}>
                        <ul>
                            <li><Link to='/' style={{color: '#000', textDecoration: 'none'}}>Главная</Link></li>
                            <li><a style={{color: '#000', textDecoration: 'none'}} href='#me-recomend'>Отзывы</a></li>
                            <li><a style={{color: '#000', textDecoration: 'none'}} href='#me-media'>Наши медиа</a></li>
                            <li><a style={{color: '#000', textDecoration: 'none'}} href='#map-person'>Карта участников</a></li>
                            <li><a  style={{color: '#000', textDecoration: 'none'}} href='#me-faq'>Ответы на вопросы</a></li>
                            <li style={{marginBottom: '8px'}}><a href={oferta} style={{ textDecoration: 'none'}} type='download'><Button appearance='default' style={{ borderRadius: '100px' }} height={40} width={'100%'}>Оферта</Button></a></li>
                            <div style={{borderTop: '1px solid rgba(0,0,0,0.56)', paddingTop: '8px'}}>
                                <li><a href='#format' style={{ textDecoration: 'none'}}><Button appearance='primary' style={{ borderRadius: '100px' }} height={40} width={'100%'}>Вступить в клуб</Button></a></li>
                                <li><Link to={'/login'} style={{ textDecoration: 'none'}}><Button appearance='default' style={{ borderRadius: '100px' }} height={40} width={'100%'}>Войти в ЛК</Button></Link></li>
                            </div>
                        </ul>
                    </div>
                </>
            )}
        </header>
    )

}
export default Header;