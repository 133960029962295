import React, { useState, useEffect } from 'react';
import '../styles/main.css';
import '../styles/lk.css';
import '../styles/flexboxgrid.min.css';
import HeaderLeft from '../components/headerLeft'
import ReactPlayer from 'react-player';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Button, TrashIcon } from 'evergreen-ui';



function VideoPage(props) {

    const data = props.data;
    const profile = props.profile;
    let { id } = useParams();
    const [videoData, setVideo] = useState([]);


    useEffect(() => {

        axios.get(`https://reps-club.com:8000/v1/videos/${id}`, {
            headers: {
                'x-access-token': localStorage.getItem("accessToken")
            }
        })

            .then(response => {
                if (response.data.length === 0) {
                    location.assign('/lk')

                }
                setVideo(response?.data);

            })
            .catch(error => {
                console.log(error);
            });

    }, []);


    const deleteVideo = async () => {
        axios.delete(`https://reps-club.com:8000/v1/del/videos/${id}`, {
            headers: {
                'x-access-token': localStorage.getItem("accessToken")
            }
        })

            .then(response => {
                toaster.success('Видео удалено', {
                    id: 'forbidden-action',
                })
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });

    }







    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Видео</p>
                            </div>
                            <div className='lk-video-content'>
                                <div className='video-player-lk'>
                                    <ReactPlayer
                                        url={'https://reps-club.com:8000' + videoData.map(item => (item.link))}
                                        controls
                                        width="100%"
                                        height="100%"
                                    />
                                    <div className='video-player-title'>
                                        <p>{videoData.map(item => (item.title))}</p>
                                    </div>
                                    <div className='video-player-description'>
                                        {data?.title === 2 &&
                                            <Button marginY={8} marginLeft={12} appearance="primary" intent="danger" onClick={() => deleteVideo()} iconBefore={TrashIcon} >
                                                Удалить ролик
                                            </Button>
                                        }
                                        <p className='video-title-section'>Описание к видео</p>
                                        <p className='video-desc-text'>{videoData.map(item => (item.description))}</p>
                                        {
                                        videoData.map(item => (item.file_link !== null && <a style={{ color: 'green', padding: '16px 16px', marginTop: '8px' }} href={`https://reps-club.com:8000${videoData.map(item => (item.file_link))}`} download>Cкачать приложение к уроку</a> )) 
                                            
                                        }
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </main>
                </div>
            </div>
        </div>
    )
}
export default VideoPage