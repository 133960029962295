import React, { useState, useEffect } from 'react';
import '../styles/main.css';
import '../styles/lk.css';
import '../styles/flexboxgrid.min.css';
import HeaderLeft from '../components/headerLeft';
import axios from 'axios';
import { Trophy } from 'akar-icons';
import imgGrant from '../content/img/grant-img-2.svg';

function MarathonPage(props) {
    const data = props.data;
    const [messageVisible, setMessageVisible] = useState(false);
    const profile = props.profile;
    const currentYear = new Date().getFullYear();

    const mounths = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];

    const [dataTable, setDataTable] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://reps-club.com:8000/v1/data');
            const fetchedData = response.data;

            const mergedData = mounths.map((mounth, index) => {
                const existingData = fetchedData.find((data) => parseInt(data.mounth) === index + 1);
               
                if (existingData) {
                    return {
                        ...existingData,
                        mounth:  index + 1,
                        mounthName:  mounth,
                        mounthIndex: index + 1,
                    };
                } else {
                    return {
                        mounthName: mounth,
                        mounth: index+1,
                        revenue: 0,
                        orderCount: 0,
                        averageCheck: 0,
                        additionalRevenue: 0,
                        profit: 0,
                        mounthIndex: index + 1,
                        user_id: 6,
                        description: 'Ваше описание',
                        isEditing: false,
                    };
                }
            });            

            setDataTable(mergedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleValueChange = (value, rowIndex, columnName) => {
        setDataTable((prevDataTable) =>
            prevDataTable.map((item, index) => {
                if (index === rowIndex) {
                    return {
                        ...item,
                        [columnName]: value,
                    };
                }
                return item;
            })
        );
    };

    const handleEditClick = (rowIndex) => {
        setDataTable((prevDataTable) =>
            prevDataTable.map((item, index) => {
                if (index === rowIndex) {
                    return {
                        ...item,
                        isEditing: true,
                    };
                }
                return item;
            })
        );
    };


    

    const handleBlur = async (rowIndex) => {
        const updatedData = dataTable[rowIndex];
        const revenue = updatedData.revenue ? parseFloat(updatedData.revenue) : 0;
        const description = updatedData.description ? updatedData.description : 'Нет описания';
        const orderCount = updatedData.orderCount ? parseInt(updatedData.orderCount) : 0;
        const averageCheck = updatedData.averageCheck ? parseFloat(updatedData.averageCheck) : 0;
        const additionalRevenue = updatedData.additionalRevenue ? parseFloat(updatedData.additionalRevenue) : 0;
        const profit = updatedData.profit ? parseFloat(updatedData.profit) : 0;
    
        try {
            if (updatedData.id) {

                // Обновление данных с помощью запроса PUT
                await axios.put(`https://reps-club.com:8000/v1/data/${updatedData.id}`, {
                    revenue: revenue,
                    orderCount: orderCount,
                    averageCheck: averageCheck,
                    additionalRevenue: additionalRevenue,
                    profit: profit,
                    description: description,
                    mounth: parseInt(updatedData.mounth),
                    user_id: updatedData.user_id,
                });
                fetchData();
                setMessageVisible(true);
                setTimeout(() => {
                    setMessageVisible(false);
                }, 2000);
            } else {
                console.log(updatedData)
                // Создание новых данных с помощью запроса POST
                await axios.post('https://reps-club.com:8000/v1/data', {
                    revenue: revenue,
                    orderCount: orderCount,
                    averageCheck: averageCheck,
                    additionalRevenue: additionalRevenue,
                    profit: profit,
                    description: description,
                    mounth: parseInt(updatedData.mounthIndex),
                    user_id: updatedData.user_id,
                });
                fetchData();
                setMessageVisible(true);
                setTimeout(() => {
                    setMessageVisible(false);
                }, 2000);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    
        setDataTable((prevDataTable) =>
            prevDataTable.map((item, index) => {
                if (index === rowIndex) {
                    return {
                        ...item,
                        isEditing: false,
                    };
                }
                return item;
            })
        );
    };
    

    function formatNumberWithSpaces(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return (
        <div className='App'>
            <div className='container-lk'>
                <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />

                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>
                                    <Trophy strokeWidth={2} size={24} /> Грант
                                </p>
                                {messageVisible && <span className='lk-save-title' style={{ fontSize: '12.5px' }}>Данные сохранены</span>}
                            </div>
                            <div
                                style={{
                                    backgroundImage: `url(${imgGrant})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    width: '100%',
                                    height: '128px',
                                }}
                            >
                            </div>
                            <div className='lk-grant-content'>
                                <div style={{ overflowX: 'auto' }}>
                                    <table style={{ borderTop: '1.5px solid #000' }} className='data-table' width='100%'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                {dataTable.map((item) => (
                                                    <th key={item.mounth}>{item.mounthName}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Выручка</td>
                                                {dataTable.map((item, rowIndex) => (
                        
                                                    <td key={item.mounth}>
                                                        {item.isEditing ? (
                                                            <>
                                                        
                                                                <input
                                                                    value={item.revenue}
                                                                    onChange={(e) => handleValueChange(e.target.value, rowIndex, 'revenue')}
                                                                    onBlur={() => handleBlur(rowIndex)}
                                                                    placeholder={item.revenue}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p onClick={() => handleEditClick(rowIndex)}>{formatNumberWithSpaces(item.revenue)}</p>
                                                            </>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>Количество заказов</td>
                                                {dataTable.map((item, rowIndex) => (
                                                    <td key={item.mounth}>
                                                        {item.isEditing ? (
                                                            <>
                                                                <input
                                                                    value={item.orderCount}
                                                                    onChange={(e) => handleValueChange(e.target.value, rowIndex, 'orderCount')}
                                                                    onBlur={() => handleBlur(rowIndex)}
                                                                    placeholder={item.orderCount}
                                                                />
                                                                
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p onClick={() => handleEditClick(rowIndex)}>{formatNumberWithSpaces(item.orderCount)}</p>
                                                            </>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>Средний чек</td>
                                                {dataTable.map((item, rowIndex) => (
                                                    <td key={item.mounth}>
                                                        {item.isEditing ? (
                                                            <>
                                                                <input
                                                                    value={item.averageCheck}
                                                                    onChange={(e) => handleValueChange(e.target.value, rowIndex, 'averageCheck')}
                                                                    onBlur={() => handleBlur(rowIndex)}
                                                                    placeholder={item.averageCheck}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p onClick={() => handleEditClick(rowIndex)}>{formatNumberWithSpaces(item.averageCheck)}</p>
                                                            </>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>Доп выручка</td>
                                                {dataTable.map((item, rowIndex) => (
                                                    <td key={item.mounth}>
                                                        {item.isEditing ? (
                                                            <>
                                                                <input
                                                                    value={item.additionalRevenue}
                                                                    onChange={(e) => handleValueChange(e.target.value, rowIndex, 'additionalRevenue')}
                                                                    onBlur={() => handleBlur(rowIndex)}
                                                                    placeholder={item.additionalRevenue}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p onClick={() => handleEditClick(rowIndex)}>{formatNumberWithSpaces(item.additionalRevenue)}</p>
                                                            </>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>Прибыль</td>
                                                {dataTable.map((item, rowIndex) => (
                                                    <td key={item.mounth}>
                                                        {item.isEditing ? (
                                                            <>
                                                                <input
                                                                    value={item.profit}
                                                                    onChange={(e) => handleValueChange(e.target.value, rowIndex, 'profit')}
                                                                    onBlur={() => handleBlur(rowIndex)}
                                                                    placeholder={item.profit}
                                                                />

                                                            </>
                                                        ) : (
                                                            <>
                                                                <p onClick={() => handleEditClick(rowIndex)}>{formatNumberWithSpaces(item.profit)}</p>
                                                            </>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>Описание для месяца</td>
                                                {dataTable.map((item, rowIndex) => (
                                                    <td key={item.mounth}>
                                                        {item.isEditing ? (
                                                            <>
                                                                <input
                                                                    value={item.description}
                                                                    onChange={(e) => handleValueChange(e.target.value, rowIndex, 'description')}
                                                                    onBlur={() => handleBlur(rowIndex)}
                                                                    placeholder={item.description}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p onClick={() => handleEditClick(rowIndex)}>{item.description === null ? 'Ваше описание': item.description}</p>
                                                            </>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </div >
    );
}

export default MarathonPage;