import React, { useState, useEffect, lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import axios from 'axios'
//import IndexPage from './pages/indexPage'
import CabinetPage from './pages/cabinetPage'
import NotifyPage from './pages/notifyPage'
import PortfolioPage from './pages/portfolioPage'
import SupportPage from './pages/supportPage'
import PastPage from './pages/pastPage'
import VideoPage from './pages/videoPage'
import PremiaPage from './pages/premiaPage'
import GrantPage from './pages/grantPage'
import EditUserPage from './pages/admin/editeUsersPage'
import VideoGridPage from './pages/videoFolderPage'
import MarathonPage from './pages/marathonPage'
import AdminWebinarPage from './pages/webinarPage'
import AdminCalendarPage from './pages/admin/calendarPage'
import AdminSupportPage from './pages/admin/supportPage'
import AdminNotifyPage from './pages/admin/notifyPage'
import AdminBranchPage from './pages/admin/branchEditorPage'
import AnketaPage from './pages/anketaPage'
import AuthPage from './pages/authPage'
import ProjectPage from './pages/projectPage'
import BranchPage from './pages/branchPage'
import CheckPayPage from './pages/checkPayPage'
import PasportPage from './pages/pasportPage'
import ProtectedRoute from './service/tokenService';
import AdminMarafonPage from './pages/admin/maraphonePage'
import BuyVideoPage from './pages/buyVideos'
import StatisticPage from './pages/admin/statisticsPage'
import Loading from './components/Loading';


import SubscriptionChecker from './service/subscribeChecker'

const IndexPage = lazy(() => import('./pages/indexPage'));

const App = () => {

  const [profile, setProfile] = useState({});
  const [user, setUser] = useState({});

  const ROLE = {
    ADMIN: true,
    USER: false,
  };

  useEffect(() => {
    const fetchUserData = async () => {
      
      await axios.get('https://reps-club.com:8000/v1/user', {
        headers: {
          'x-access-token': localStorage.getItem("accessToken")
        }
      })
        .then(response => {
          setProfile(response.data.profile);
          setUser(response.data.user);
        })
        .catch(error => {
          console.error(error);
        });
    }
    if (localStorage.getItem('accessToken') !== null) {
    fetchUserData();
    }
  }, []);




  return (

    <BrowserRouter forceRefresh={true}>
      <Suspense fallback={<Loading />}>
        <Routes>

          <Route index exact path="/" element={<IndexPage />} />
          <Route path="/login" element={<AuthPage />} />
          <Route path="/project/:id" element={<ProjectPage />} />
          <Route path="/pasport" element={<PasportPage />} />
          <Route path="/branch" element={<BranchPage />} />
          <Route path="/sub/videos" element={<BuyVideoPage />} />
          <Route path="/pay-status/:paymentId" element={<CheckPayPage />} />

          <Route force element={<ProtectedRoute role={ROLE.USER} />} path="/lk">
            <Route path="/lk" element={<CabinetPage data={user} profile={profile} />} />
            <Route path="portfolio" element={<PortfolioPage data={user} profile={profile} />} />
            <Route path="anketa" element={<AnketaPage data={user} profile={profile} />} />
            <Route path="support" element={<SupportPage data={user} profile={profile} />} />
            <Route element={<SubscriptionChecker />}>
              <Route path="video/past" element={<PastPage data={user} profile={profile} />} />
              <Route path="video/:id" element={<VideoPage data={user} profile={profile} />} />
              <Route path="video/grid/:id" element={<VideoGridPage data={user} profile={profile} />} />
              <Route path="marathon" element={<MarathonPage data={user} profile={profile} />} />
              <Route path="grant" element={<GrantPage data={user} profile={profile} />} />
              <Route path="notify" element={<NotifyPage data={user} profile={profile} />} />
              <Route path="premia" element={<PremiaPage data={user} profile={profile} />} />
            </Route>
          </Route>
          <Route element={<ProtectedRoute role={ROLE.ADMIN} />}>
            <Route path="/lk/admin/webinar" element={<AdminWebinarPage data={user} profile={profile} />} />
            <Route path="/lk/admin/branch" element={<AdminBranchPage data={user} profile={profile} />} />
            <Route path="/lk/admin/support" element={<AdminSupportPage data={user} profile={profile} />} />
            <Route path="/lk/admin/calendar" element={<AdminCalendarPage data={user} profile={profile} />} />
            <Route path="/lk/admin/notify" element={<AdminNotifyPage data={user} profile={profile} />} />
            <Route path="/lk/admin/marafon" element={<AdminMarafonPage data={user} profile={profile} />} />
            <Route path="/lk/admin/edit/users" element={<EditUserPage data={user} profile={profile}/>}/>
            <Route path="/lk/admin/statistics" element={<StatisticPage data={user} profile={profile}/>}/>
  
          </Route>

          <Route path="*" element={<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><div style={{ textAlign: 'center' }}><p style={{ display: 'block', fontSize: '48px', marginBottom: 8, fontWeight: 500 }}>Упс... такой страницы нет! 🙁</p><a href='/' style={{ fontWeight: 400, fontSize: 24, color: '#90aff9', textDecoration: 'none', borderBottom: '1.5px dashed #90aff9', paddingBottom: '4px' }}>Вернуться на главную</a></div></div>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
console.log('😊🙃😉 Интересно зачем Вы сюда полезли, но если хотите снами связаться по вопросам партнерства, то просто напишите на почту atelu@mail.ru')
export default App;



/*
<BrowserRouter>
  <Routes>
    <Route path="/" element={<IndexPage />} />
    <Route exact element={<AuthRoutes />}>
      <Route path="/login" element={<AuthPage />} />
    </Route>
    <Route exact element={<PrivateRoute />}>
      <Route path="/lk" element={<CabinetPage data={user} profile={profile} />} />
      <Route path="/lk/notify" element={<NotifyPage data={user} profile={profile} />} />
      <Route path="/lk/portfolio" element={<PortfolioPage data={user} profile={profile} />} />
      <Route path="/lk/anketa" element={<AnketaPage data={user} profile={profile} />} />
      <Route path="/lk/support" element={<SupportPage data={user} profile={profile} />} />
      <Route path="/lk/video/past" element={<PastPage data={user} profile={profile} />} />
    </Route>


    <Route exact element={<PrivateRoute roles={ROLE.ADMIN} />}>
      <Route path="/lk/admin/webinar" element={<AdminWebinarPage data={user} profile={profile} />} />
      <Route path="/lk/admin/support" element={<AdminSupportPage data={user} profile={profile} />} />
      <Route path="/lk/admin/calendar" element={<AdminCalendarPage data={user} profile={profile} />} />
      <Route path="/lk/admin/notify" element={<AdminNotifyPage data={user} profile={profile} />} />
    </Route>
  </Routes>
</BrowserRouter >
*/