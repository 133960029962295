import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/main.css';
import '../styles/lk.css';
import '../styles/flexboxgrid.min.css';
import HeaderLeft from '../components/headerLeft'
import { Play } from 'akar-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


function PastPage(props) {
    const data = props.data;
    const profile = props.profile;
    const [videos, setDataVideos] = useState([]);
    useEffect(() => {
        axios.get(`https://reps-club.com:8000/v1/videos/alphabet/3`, {
            headers: {
                'x-access-token': localStorage.getItem("accessToken")
            }
        })
            .then(response => {
                setDataVideos(response.data);
            })
            .catch(error => {
                console.log('Ошибка получения видеороликов')
            });
    }, [])
    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Прошедшие вебинары</p>
                            </div>
                            <div className='lk-past-video-content'>
                                <div className='row'>

                                    {
                                        videos.length > 0 ? 
                                            videos.map((video, index) =>
                                            (
                                                <div className='col-md-4 col-xs-12 col-sm-6'>

                                                    <div className="image-block">
                                                        <p className="image-label">{video.title}</p>
                                                        <div className="image-wrapper">
                                                            <LazyLoadImage
                                                                alt="Фотография"
                                                                effect="blur"
                                                                src={'https://reps-club.com:8000' + video.preview}
                                                                key={index}
                                                            />
                                                        </div>
                                                        <div className="play-icon" onClick={() => window.location.replace('/lk/video/'+video.id)}>
                                                            <div className='play-icon-border'>
                                                                <Play strokeWidth={1} size={32} />
                                                            </div>
                                                        </div>
                                                        <div className="header-labels">
                                                            <span>Доступно до {video.end_time}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            : 
                                            <p className='lk-alphabet-none'>Видеоролики еще не добавили</p>
                                    }
                                </div>
                            </div>
                        </section>

                    </main>
                </div>
            </div>
        </div>
    )
}
export default PastPage

{/* <picture className="lk-card_video-v">
                                            <div className='lk-card-label-video'>
                                                <span>Новое</span>
                                            </div>
                                            <div className='lk-card-play-video'>
                                                <Play strokeWidth={1} size={32} />
                                            </div>
                                            <p className="lk-card_video-v-link">
                                                <LazyLoadImage
                                                    alt={"Фотография проекта"}
                                                    effect="blur"
                                                    src={'https://sher.media/wp-content/uploads/2020/10/2795340439_bacf3599c7_o.jpg'}
                                                    key={1}

                                                />
                                            </p>
                                            <p className='lk-card-name-webinar'>
                                                Делаем жизнь в городе ярче
                                            </p>
                                        </picture> */}