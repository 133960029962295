
import React, { useEffect, useState } from 'react';
import '../styles/lk.css';
import { Door, Camera, Microphone, Star } from 'akar-icons';
import StickyBox from "react-sticky-box";
import { Avatar } from 'evergreen-ui';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Verified from "../content/img/Verified.svg"
import io from 'socket.io-client';
import BottomNavigation from './bottomMenu';


function HeaderLeft(props) {
    const socket = io('https://reps-club.com:8080');
    const location = useLocation();
    const navigate = useNavigate();
    const user = props.user !== undefined ? (props.user) : (false);
    const profile = props.profileInfo !== null ? (props.profileInfo) : (false);
    const displayName = profile.fio ? `${profile.fio.split(' ')[0]} ${profile.fio.split(' ')[1]}` : '';
    // const [isAdminMenu, setIsAdminMenu] = useState(false);
    //${profile.fio.split(' ')[2].charAt(0)}.
    let title = '';


    const [newDataCount, setNewDataCount] = useState(0);
    useEffect(() => {

        socket.on('newDataStatus', (count) => {
            setNewDataCount(count);
        });


        socket.emit('checkNewData', user?.id);

        return () => {
            socket.off('newDataStatus');
        };
    }, []);


    if (user.title) {
        if (user.title === 1) {
            title = 'Член клуба';
        } else if (user.title === 2) {
            title = 'Администратор';
            // setIsAdminMenu(true);
        } else {
            title = 'Пользователь';
        }
    }

    function logout() {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        navigate("/");

    }


    return (
        <>
            <StickyBox style={{ display: 'flex' }}>
                <div className='nav-left'>
                    <div className='welcome-menu'>
                        <p>Добро пожаловать!</p>
                    </div>
                    <div className='profile-menu'>
                        <a href='/lk/anketa' className='profile-user-block'>
                            <div className='profile-avatar'>
                                <LazyLoadComponent>
                                    <div className='avatar-header-left-verified'>
                                        <Avatar name={profile.fio ? (displayName) : ('Неизвестный')} size={56} src={profile.photo} />
                                        {user?.verified &&
                                            <div className="icon-verified">
                                                <img src={Verified} alt="Подтвержденный аккаунт" width='16px' />
                                            </div>
                                        }
                                    </div>
                                </LazyLoadComponent>
                            </div>

                            <div className='profile-username'>
                                <p> {profile.fio ? (displayName) : ('Неизвестный')} <span>{title}</span></p>
                            </div>
                        </a>
                        <div className='profile-exit' onClick={() => logout()}>
                            <p title='Выйти из аккаунта'><Door strokeWidth={2} size={24} /></p>
                        </div>
                    </div>
                    <div className='lk-menu'>
                        <div className={location.pathname === '/' ? 'lk-default-link lk-link-webinar active-default-link' : 'lk-default-link lk-link-webinar'}>
                            <Link to='/'>Основная страница<span style={{ background: '#FBE4AA', marginLeft: '8px', color: 'black' }} title={'Главная'}><Star strokeWidth={1} size={14} /></span></Link>
                        </div>
                        <div className={location.pathname === '/lk' ? 'lk-default-link active-default-link' : 'lk-default-link'}>
                            <Link to='/lk'>Главная</Link>
                        </div>
                        <div className={location.pathname === '/lk/notify' ? 'lk-default-link lk-notification active-default-link' : 'lk-default-link lk-notification'}>
                            <Link to='/lk/notify'>Уведомления <span>{newDataCount}</span></Link>
                        </div>
                        <div className={location.pathname === '/lk/portfolio' ? 'lk-default-link active-default-link' : 'lk-default-link'} >
                            <Link to='/lk/portfolio'>Мое портфолио</Link>
                        </div>
                        <div className={location.pathname === '/lk/marathon' ? 'lk-default-link active-default-link' : 'lk-default-link'}>
                            <Link to='/lk/marathon'>Марафон "Автоматизация"</Link>
                        </div>
                        <div className={location.pathname === '/lk/video/past' ? 'lk-default-link active-default-link' : 'lk-default-link'}>
                            <Link to='/lk/video/past'>Записи прошедших вебинаров</Link>
                        </div>
                        <div className={location.pathname === '/lk/premia' ? 'lk-default-link active-default-link' : 'lk-default-link'}>
                            <Link to='/lk/premia'>Премия Reps Club</Link>
                        </div>
                    </div>
                    {user?.title  === 1 &&
                        <div className='lk-support'>
                            <div className={location.pathname === '/lk/support' ? 'lk-default-link active-default-link' : 'lk-default-link'}>
                                <Link to='/lk/support'>Поддержка</Link>
                            </div>
                        </div>
                    }
                    {user.title === 2 && (
                        <div className='lk-admin'>
                            <div className={location.pathname === '/lk/admin/calendar' ? 'lk-default-link active-default-link' : 'lk-default-link'}>
                                <Link to='/lk/admin/calendar'>Изменить календарь</Link>
                            </div>
                            <div className={location.pathname === '/lk/admin/webinar' ? ' lk-default-link lk-link-webinar active-default-link' : 'lk-default-link lk-link-webinar'}>
                                <Link to='/lk/admin/webinar'>Google Meet  <span title={'Камера'}><Camera strokeWidth={1} size={14} /></span><span title={'Мирофон'}><Microphone strokeWidth={1} size={14} /></span></Link>
                            </div>
                            <div className={location.pathname === '/lk/admin/marafon' ? 'lk-default-link active-default-link' : 'lk-default-link'}>
                                <Link to='/lk/admin/marafon'>Добавить марафон</Link>
                            </div>
                            <div className={location.pathname === '/lk/admin/branch' ? 'lk-default-link active-default-link' : 'lk-default-link'}>
                                <Link to='/lk/admin/branch'>Редактор лендинга</Link>
                            </div>
                            {/* <div className={location.pathname === '/lk/admin/notify' ? 'lk-default-link active-default-link' : 'lk-default-link'}>
                                <Link to='/lk/admin/notify'>Отправить уведомление</Link>
                            </div> */}
                            <div className='lk-default-link'>
                                <Link to='/lk/admin/statistics'>Статистика</Link>
                            </div>
                            <div className={location.pathname === '/lk/admin/edit/users' ? 'lk-default-link active-default-link' : 'lk-default-link'}>
                                <Link to='/lk/admin/edit/users'>Редактор пользователей</Link>
                            </div>
                            <div className={location.pathname === '/lk/admin/support' ? 'lk-default-link lk-notification active-default-link' : 'lk-default-link lk-notification'}>
                                <Link to='/lk/admin/support'>Письма из поддержки <span>0</span></Link>
                            </div>
                        </div>
                    )
                    }

                </div>
            </StickyBox>
            <BottomNavigation />
        </>
    )

}
export default HeaderLeft