
import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../styles/main.css';
import '../../styles/branch.css';
import '../../styles/flexboxgrid.min.css';
import '../../styles/lk.css'

import logo from '../../content/img/logo-wh.svg'

import { Thunder, Image, Money } from 'akar-icons'
import Telegram from '../../content/img/telegram.svg'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import HeaderLeft from '../../components/headerLeft'
import { Button, TextareaField, toaster, TextInput, TextInputField } from 'evergreen-ui'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function BranchPage(props) {
    const navigate = useNavigate();
    const data = props.data;
    const profile = props.profile;
    const getBlockRef = useRef(null);

    const [htmlCode, setHtmlCode] = useState('');


    useEffect(() => {
        if (getBlockRef.current) {
            setHtmlCode(getBlockRef.current.innerHTML);
        }
    }, [getBlockRef]);




    const [landing, setLanding] = useState([]);


    useEffect(() => {
        axios.get('https://reps-club.com:8000/v1/landing')
            .then(response => {

                setLanding(response?.data);
            })
            .catch(error => {
                console.log(error);
            });

    }, []);


    const handleChangePicture = async () => {
        try {

            const formData = new FormData();
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/*';

            const file = await new Promise((resolve) => {
                fileInput.addEventListener('change', (event) => {
                    resolve(event.target.files[0]);
                });
                fileInput.click();
            });
            if (file) {
                const element = document.querySelector('.bg-banner-photo');
                const imageUrl = `url(${URL.createObjectURL(file)})`;
                element.style.backgroundImage = imageUrl;

                formData.append('bg', file);
                await axios.put('https://reps-club.com:8000/v1/landing/bg', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': localStorage.getItem("accessToken")
                    }
                }).then((response) => {

                    toaster.success('Фон успешно изменен', {
                        id: 'forbidden-action',
                    })
                })
                    .catch((error) => {
                        toaster.danger('Упс! Что-то пошло не так!', error, {
                            id: 'forbidden-action',
                        })
                    });
            }

        } catch (error) {
            console.error(error);
        }
    };






    const [placemarkGeometry, setPlacemarkGeometry] = useState([55.751574, 37.573856]);



    const getRandomColor = useMemo(() => {
        const colors = ["#90AFF9", "#ECA0BF", "#C7D2A0"];
        let previousIndex = -1;

        return () => {
            let randomIndex = Math.floor(Math.random() * colors.length);
            while (randomIndex === previousIndex) {
                randomIndex = Math.floor(Math.random() * colors.length);
            }
            previousIndex = randomIndex;
            return colors[randomIndex];
        };
    }, []);


    const [selectedElement, setSelectedElement] = useState(null);
    const [selectedElement2, setSelectedElement2] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(0,0);
    const [isShowSetting, setShowSetting] = useState(0,0);
    const [newContent, setNewContent] = useState('');
    const [preTitle, setPreTitle] = useState('Шторный супербранч');
    const [title, setTitle] = useState('"Москва-Питер"');
    const [dateBanner, setDateBanner] = useState(`30 июля 2023 года в 18:00<br/>
    город Санкт - Петербург, улица Пушкина, дом 15`);
    const [welcomeText, setWelcomeText] = useState('Reps Club приглашает всех, кто хочет, чтобы любимая работа стала успешным бизнесом и осталась любимым делом');
    const [price, setPrice] = useState(4900);
    useEffect(() => {
        setPreTitle(landing.map(item => (item.preTitle)).toString());
        setTitle(landing.map(item => (item.title)).toString());
        setDateBanner(landing.map(item => (item.dateBanner)).toString());
        setWelcomeText(landing.map(item => (item.welcomeText)).toString());
        setPrice(landing.map(item => parseInt(item.price)));
        setLongitude(landing.map(item => parseFloat(item.longitude)));
        setLatitude(landing.map(item => parseFloat(item.latitude)));

    }, [landing])


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'landing1':
                setPreTitle(value);
                break;
            case 'landing2':
                setTitle(value);
                break;
            case 'landing3':
                setDateBanner(value);
                break;
            case 'landing4':
                setWelcomeText(value);
                break;
            case 'landing5':
                setPrice(value);
                break;
            default:
                break;
        }
    };

    const handleElementClick = () => {
        setShowSetting(true)
    };




    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            if (address.length > 1) {
                await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.length > 0) {
                            setLatitude(parseFloat(data[0].lat));
                            setLongitude(parseFloat(data[0].lon));

                            axios.put('https://reps-club.com:8000/v1/landing/info', {
                                preTitle: preTitle,
                                title: title,
                                dateBanner: dateBanner,
                                welcomeText: welcomeText,
                                latitude: parseFloat(data[0].lat),
                                longitude: parseFloat(data[0].lon),
                                price: parseInt(price)
                            }, {
                                headers: {
                                    'x-access-token': localStorage.getItem("accessToken")
                                }
                            }).then((response) => {
                                toaster.success('Данные успешно обновлены', {
                                    id: 'forbidden-action',
                                })
                                isShowSetting(false)
                            })
                                .catch((error) => {
                                    console.error(error);
                                });

                        } else {
                            setLatitude('Невозможно найти координаты');
                            setLongitude('');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setLatitude('Ошибка при получении координат');
                        setLongitude('');
                    });


            } else {
                axios.put('https://reps-club.com:8000/v1/landing/info', {
                    preTitle: preTitle,
                    title: title,
                    dateBanner: dateBanner,
                    welcomeText: welcomeText,
                    latitude: parseFloat(latitude),
                    longitude: parseFloat(longitude),
                    price: parseInt(price)
                }, {
                    headers: {
                        'x-access-token': localStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    toaster.success('Данные успешно обновлены', {
                        id: 'forbidden-action',
                    })
                    isShowSetting(false)
                })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        } catch (error) {
            console.error(error);
        }

    }














    const handleElementGet = (event) => {
        const clickedElement = event.target;
        setSelectedElement(clickedElement);
        setNewContent(clickedElement.innerHTML);
    };


    const handleElementFaq = (event) => {
        const clickedElement = event.target;
        setSelectedElement2(clickedElement);
        setNewContent(clickedElement.innerHTML);
    };

    const handleInputChangeGet = (event) => {
        setNewContent(event.target.value);
    };

    const handleSaveButtonClickGet = () => {
        if (selectedElement) {
            if (newContent.trim() === '') {
                const shouldDelete = window.confirm('Вы хотите удалить этот блок?');
                if (shouldDelete) {
                    selectedElement.closest('.col-md-3').remove();
                }
            } else {
                selectedElement.innerHTML = newContent;
            }
        }
        setHtmlCode(getBlockRef.current.innerHTML);
        setSelectedElement(null);
        setNewContent('');
    };

    const handleSaveButtonClickFaq = () => {
        if (selectedElement2) {
            if (newContent.trim() === '') {
                const shouldDelete = window.confirm('Вы хотите удалить этот блок?');
                if (shouldDelete) {
                    selectedElement2.closest('.item-faq').remove();
                }
            } else {
                selectedElement2.innerHTML = newContent;
            }
        }
        setHtmlCode(getBlockRef.current.innerHTML);
        setSelectedElement2(null);
        setNewContent('');
    };





    const updateGets = () => {
        try {

            axios.put('https://reps-club.com:8000/v1/landing/gets', {
                htmlCode: document.querySelector('.row-flex.get-block').innerHTML
            }, {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then((response) => {
                toaster.success('Данные успешно обновлены', {
                    id: 'forbidden-action',
                })

            })
                .catch((error) => {
                    toaster.danger('Упс! Что-то пошло не так :(', {
                        id: 'forbidden-action',
                    })
                });
        } catch (error) {
            console.error(error);
        }
    }

    const updateDays = () => {
        try {

            axios.put('https://reps-club.com:8000/v1/landing/days', {
                htmlCode: document.querySelector('.faq-block').innerHTML
            }, {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then((response) => {
                toaster.success('Данные успешно обновлены', {
                    id: 'forbidden-action',
                })

            })
                .catch((error) => {
                    toaster.danger('Упс! Что-то пошло не так :(', {
                        id: 'forbidden-action',
                    })
                });
        } catch (error) {
            console.error(error);
        }
    }

    const [elements, setElements] = useState([]);
    const [elements2, setElements2] = useState([]);

    const handleCreateBlock = () => {
        const newElement = (
            <div className='col-md-3' key={elements.length}>
                <div className='get-card'>
                    <div className='get-icon' style={{ backgroundColor: getRandomColor() }} onClick={handleElementGet}>
                        <Thunder strokeWidth={1.5} size={24} />
                    </div>
                    <div className='get-text' onClick={handleElementGet}>
                        <p>Текст</p>
                    </div>
                </div>
            </div>
        );

        setElements(prevElements => [...prevElements, newElement]);
    };


    const handleCreateBlock2 = () => {
        const newElement = (
            <div className='item-faq' >
                <div className='item-faq-header'>
                    <p className='faq-title-item' >1 день, понедельник</p>
                    <p className='faq-button-item' style={{ width: '100%' }}>Какой то текст напиши его</p>
                </div>
            </div>
        );
        setElements2(prevElements => [...prevElements, newElement]);
    };



    useEffect(() => {
        const generateHTMLCode = () => {
            const array = document.querySelector('.row-flex.get-block').innerHTML = landing.map(item => (item.arrayElements));
            return array;
        }
        generateHTMLCode();
    }, [landing]);

    useEffect(() => {
        const generateHTMLCode = () => {
            const array = document.querySelector('.faq-block').innerHTML = landing.map(item => (item.arrayDays));
            return array;
        }
        generateHTMLCode();
    }, [landing]);

    const [address, setAddress] = useState('');


    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleGetCoordinates = async () => {
        await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    setLatitude(parseFloat(data[0].lat));
                    setLongitude(parseFloat(data[0].lon));


                } else {
                    setLatitude('Невозможно найти координаты');
                    setLongitude('');
                }
            })
            .catch((error) => {
                console.error(error);
                setLatitude('Ошибка при получении координат');
                setLongitude('');
            });
    };






    return (
        <div className='App'>
            <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                <HeaderLeft user={data} profileInfo={profile} />
                <div style={{ width: '80%', position: 'relative' }}>
                    <div className='element-edito-landing'>
                        <p onClick={handleChangePicture}><Image strokeWidth={1.5} size={20} /> изменить фон</p>
                        {/* <p><Money strokeWidth={1.5} size={20} /> ссылка оплаты</p> */}
                    </div>
                    <div id="block" className='block-banner'>
                        <div className='banner-branch'>

                            <div className='banner-branch-content'>
                                <div className='content-banner'>
                                    <div className='info-banner'>
                                        <h2 onClick={handleElementClick}>{preTitle}</h2>
                                        <h3 onClick={handleElementClick}>{title}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: `${dateBanner}` }} className='date-branch' onClick={handleElementClick} />
                                        <button className='buy-pass' onClick={handleElementClick}>Купить билет</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-banner-photo' style={{ backgroundImage: `url(${landing.map(item => ('https://reps-club.com:8000' + item.bg))})` }}>

                        </div>
                    </div>
                    <main>
                        <section className='section-one'>
                            <p onClick={handleElementClick} dangerouslySetInnerHTML={{ __html: `${welcomeText}` }} />
                        </section>
                        <section className='section-two'>
                            <p className='branch-section-title' onClick={() => console.log(htmlCode)}>На шторном бранче вы получите</p>
                            <div className='row-flex get-block' onClick={handleElementGet} ref={getBlockRef}>{elements}</div>
                            <Button height={48} width={'100%'} onClick={() => updateGets()} marginBottom={16} appearance='primary'>Cохранить этот блок</Button>
                            <Button height={48} width={'100%'} onClick={() => handleCreateBlock()}>Добавить блок</Button>
                        </section>
                        <section className='section-three'>
                            <p className='branch-section-title' ref={getBlockRef}>Расписание Шторного бранча</p>
                            <div className='faq-block' onClick={handleElementFaq}>
                                {elements2}
                            </div>

                            <Button height={48} width={'100%'} onClick={() => updateDays()} marginBottom={16} marginTop={16} appearance='primary'>Cохранить этот блок</Button>
                            <Button height={48} width={'100%'} onClick={() => handleCreateBlock2()}>Добавить блок</Button>
                        </section>
                    </main>
                    <footer>
                        <div className='footer-branch-map'>
                            <div className='row-flex'>
                                <div className='col-md-6'>
                                    <div className='map-information-branch'>
                                        <p className='map-information-title'>
                                            Место встречи
                                        </p>
                                        <div className='map-information-adress'>
                                            <p>Адрес</p>
                                            <a href='/' target='_blank' dangerouslySetInnerHTML={{ __html: `${landing.map(item => (item.dateBanner))}` }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <YMaps>
                                        <div style={{ width: "100%", height: "412px" }}  >
                                            <Map
                                                defaultState={{
                                                    center: landing.map(item => (item.latitude)).length > 0 ? [landing.map(item => (item.latitude)), landing.map(item => (item.longitude))] : [55.684758, 37.738521],
                                                    zoom: 16,
                                                }}
                                                width={'100%'}
                                                height={'412px'}
                                            >
                                                <Placemark geometry={landing.map(item => (item.latitude)).length > 0 ? [landing.map(item => (item.latitude)), landing.map(item => (item.longitude))] : [55.684758, 37.738521]} />
                                            </Map>
                                        </div>
                                    </YMaps>
                                </div>
                            </div>
                        </div>
                        <div className='main_footer'>
                            <div className='footer-content container'>
                                <div className='row'>
                                    <div className='col-md-2 col-xs-12'>
                                        <div className='footer-logo'>
                                            <img src={logo} alt='Reps Club' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='footer-section'>
                                            <p className='footer-section-title'>По вопросам партнерства</p>
                                            <div className='footer-contact-list'>
                                                <p>Почта: <a href='mailto:atelu@mail.ru'>atelu@mail.ru</a></p>
                                                <p>Телефон: <a href="tel:+79165389155">+ 7 (916) 538 91-55</a></p>
                                                <p>Телеграм: <a href='https://t.me/Lozovskaya_atelu'>@Lozovskaya_atelu</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='footer-section'>
                                            <p className='footer-section-title'>Подпишись на нас</p>
                                            <div className='footer-social-media'>
                                                <a href='/#'><img src={Telegram} alt='Телеграм Лазовская Юлия' /></a>
                                                {/* <a href='/#'><img src={Instagram} alt='Инстаграм Лазовская Юлия' /></a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <p className='info-meta'>Meta признана экстремистcкой организацией в России» или «Instagram, продукт компании Meta, которая признана экстремистской организацией в России</p> */}
                            </div>
                        </div>
                        <div className='pre_footer'>
                            <p className='footer-copy'>© Reps Club, 2023</p>
                        </div>
                    </footer>
                </div>

                {isShowSetting && (
                    <div className='editor-landing-fixed'>
                        <div>
                            <form className='form-landing-edit' onSubmit={handleSubmit}>
                                <p>Редактор</p>
                                <TextInput value={preTitle} width={'100%'} marginBottom={'16px'} onChange={handleInputChange} name='landing1' height={48} />
                                <TextInput value={title} width={'100%'} marginBottom={'16px'} onChange={handleInputChange} name='landing2' height={48} />
                                <TextInput value={dateBanner} width={'100%'} marginBottom={'16px'} onChange={handleInputChange} name='landing3' height={48} />
                                <TextInput value={welcomeText} width={'100%'} marginBottom={'16px'} onChange={handleInputChange} name='landing4' height={48} />
                                <p style={{ fontSize: '16px' }}>Не забудьте изменить <br /> стоимость бранча</p>
                                <TextInput value={price} width={'100%'} marginBottom={'16px'} onChange={handleInputChange} name='landing5' height={48} />
                                {<>
                                    {latitude}
                                    {longitude}
                                </>}
                                <TextInputField label={'Адрес'} width={'100%'} description={'Укажите тут только адрес ( пример: Нарышкинская аллея 5с2)'} type="text" defaultValue={address} onChange={handleAddressChange} inputHeight={48} />
                                <Button appearance='primary' width={'100%'} marginBottom={'16px'} marginTop={'24px'} height={48}>Сохранить</Button>
                            </form>
                            <Button appearance='default' marginTop={'8px'} width={'100%'} height={48} onClick={() => setShowSetting(false)}>Закрыть</Button>
                        </div>
                    </div>
                )}

                {selectedElement && (
                    <div className='editor-landing-fixed'>
                        <div>

                            <TextareaField
                                label="Укажи новое значение"
                                required
                                description="Нажми сохранить"
                                value={newContent}
                                onChange={handleInputChangeGet}
                            />
                            {/* <textarea value={newContent} onChange={handleInputChange} /> */}
                            <Button onClick={handleSaveButtonClickGet} appearance="primary" width={'100%'} height={48}>Сохранить</Button>
                            <Button appearance='default' marginTop={'8px'} width={'100%'} height={48} onClick={() => setSelectedElement(null)}>Закрыть</Button>
                        </div>
                    </div>
                )}

                {selectedElement2 && (
                    <div className='editor-landing-fixed'>
                        <div>

                            <TextareaField
                                label="Укажи новое значение"
                                required
                                description="Нажми сохранить"
                                value={newContent}
                                onChange={handleInputChangeGet}
                            />
                            {/* <textarea value={newContent} onChange={handleInputChange} /> */}
                            <Button onClick={handleSaveButtonClickFaq} appearance="primary" width={'100%'} height={48}>Сохранить</Button>
                            <Button appearance='default' marginTop={'8px'} width={'100%'} height={48} onClick={() => setSelectedElement2(null)}>Закрыть</Button>
                        </div>
                    </div>
                )}


            </div>
        </div>
    )
}
export default BranchPage