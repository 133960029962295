import React, { useEffect} from 'react';
import axios from 'axios';
import { Outlet, useNavigate} from 'react-router-dom';

function SubscriptionChecker() {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            axios.get('https://reps-club.com:8000/v1/user', {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then(function (res) {
                const currentDate = new Date();
                const endSubscribeDate = new Date(res.data.user.end_subscribe);
                if (currentDate > endSubscribeDate) {
                 
                     navigate('/lk/anketa');
                     window.location.replace(
                        "/lk/anketa",
                      );
                }
              
            }).catch(function () {

            })
        } 
    }, []);


    return (
        <Outlet />
      );
    //return isSubscribe === true ? <Outlet /> : <Navigate to="/lk/anketa" />;

}

export default SubscriptionChecker;