import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toaster } from 'evergreen-ui';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/main.css';
import '../styles/flexboxgrid.min.css';
import Header from '../components/header'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Avatar, Badge } from 'evergreen-ui';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper';

function PortfolioPage(props) {
    let { id } = useParams();
    const navigate = useNavigate();

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    const [arrProject, setProject] = useState([]);
    const [arrMedia, setMedia] = useState([]);
    const [arrProfile, setProfile] = useState([]);

    function addHttpsPrefix(url) {
        if (!url.startsWith("https://")) {
            return "https://" + url;
        }
        return url;
    }

    useEffect(() => {

        axios.get(`https://reps-club.com:8000/v1/project/${id}`, {
            headers: {
                'x-access-token': localStorage.getItem("accessToken")
            }
        }).then(function (res) {
            setProject(res.data);
            if (res.data.length > 0) {
                const extractedMediaArray = res.data.map(item => item.media).flat();
                const extractedProfileArray = res.data.map(item => item.profile).flat();
                setMedia(extractedMediaArray);
                setProfile(extractedProfileArray);
                console.log(extractedProfileArray);
            } else {
                navigate('/404', { replace: true });
                window.location.reload();
            }

        })

    }, []);

    return (
        <div className="App">
            <Header />
            <main className='project_main'>
                <div className='container-fluid'>
                    <div className='row-flex'>
                        <div className='col-md-6'>
                            <div className='project-photo-slider'>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 11000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    onAutoplayTimeLeft={onAutoplayTimeLeft}
                                    className="mySwiper"
                                >
                                    {arrMedia.map(mediaItem => (
                                        mediaItem.linkMedia.map((link, index) => (
                                            <SwiperSlide key={index} className='project-slide-img'><img src={link} alt={"Фотография " + index + " проекта"} loading="lazy" key={link} /></SwiperSlide>
                                        ))
                                    ))}
                                    <div className="autoplay-progress" slot="container-end">
                                        <svg viewBox="0 0 48 48" ref={progressCircle}>
                                            <circle cx="24" cy="24" r="20"></circle>
                                        </svg>
                                        <span ref={progressContent}></span>
                                    </div>
                                </Swiper>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className='project-info'>
                                {arrProject.map(item => (
                                    <h1>{item.nameProject}</h1>
                                ))}
                                {arrProfile.map(item => (
                                    <div className='project-info-author'>
                                       {item.photo === null  ?
                                        <Avatar name={item.fio} size={40} color="green" />
                                        :
                                        <Avatar name={item.fio} size={40} color="green" src={item.photo}/>
                                       }
                                        <p>{item.fio} <span><a href={addHttpsPrefix(item.siteLink)} target='_blank' rel="noreferrer">{item.siteLink}</a></span></p>
                                    </div>
                                ))}
                                <div className='project-info-description'>

                                    <p className='project-info-title'>Описание проекта</p>

                                    {arrProject.map(item => (
                                         <p  className='project-info-text' dangerouslySetInnerHTML={{ __html: item.description.replace(/\r/g, '<br/>') }}/>
                
                                    ))}

                                </div>
                                <div className='project-info-designer'>
                                    <p>Над проектом работал:</p>
                                    {arrProject.map(item => (
                                        <a href={addHttpsPrefix(item.linkDesigner)}>Дизайнер: {item.fioDesigner}</a>
                                    ))
                                    }
                                </div>
                                <div className='row' style={{ marginTop: '24px'}}>
                                    <div className='project-info-designer col-md-6 col-xs-12 col-sm-6'>
                                        <p>Были использованы ткани фирм:</p>
                                        {
                                            arrProject.map((item, index) => (
                                                item.tkani.map((text, index) => (
                                                    <Badge key={index} color="blue" marginRight={8}>
                                                        {text}
                                                    </Badge>
                                                ))
                                            ))
                                        }
                                    </div>
                                    <div className='project-info-designer col-md-6 col-xs-12 col-sm-6'>
                                        <p>Была использована фурнитура фирм:</p>
                                        {
                                            arrProject.map((item, index) => (
                                                item.furnitura.map((text, index) => (
                                                    <Badge key={index} color="teal" marginRight={8}>
                                                        {text}
                                                    </Badge>
                                                ))
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </main >
        </div >
    )
}

export default PortfolioPage