import axios from 'axios';
import React, { useState } from 'react';

const PaymentForm = () => {
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');

  const handlePayment = async (event) => {
    event.preventDefault();

    // Отправляем запрос на создание платежа на сервер
    const response = await axios.post('https://reps-club.com:8000/v1/create-payment', {
      body: {
        amount,
        description,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const payment = await response.json();
      // Перенаправляем пользователя на страницу оплаты
      window.location.href = payment.confirmation.confirmation_url;
    } else {
      console.log('Error creating payment');
    }
  };

  return (
    <form onSubmit={handlePayment}>
      <input
        type="number"
        placeholder="Amount"
        value={amount}
        onChange={(event) => setAmount(event.target.value)}
      />
      <input
        type="text"
        placeholder="Description"
        value={description}
        onChange={(event) => setDescription(event.target.value)}
      />
      <button type="submit">Pay</button>
    </form>
  );
};

export default PaymentForm;