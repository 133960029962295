import React, { useState, useEffect } from 'react';
import { Button, Checkbox, TextInputField, toaster } from 'evergreen-ui'
import { Ticket } from 'akar-icons';
import axios from 'axios';

import styled from 'styled-components'
const StyledDiv = styled.div`
.modal-branch{
position: fixed;
z-index: 9;
background: rgba(0,0,0,0.24);
width: 100%;
height: 100%;
left: 0;
right: 0;
align-items: center;
justify-content: center;
display: flex;
}
.modal-branch.hidden{
    display:none;
}
.modal-branch-content{
    position: relative;
}
.ticket {
    width: 100%;
    max-width: 356px;
    min-width: 312px;
    height: 100%;
    background: white;
    min-height: 412px;
    border-radius: 32px;
  }
  .modal-branch-content .close{
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 16px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #000;
    border-radius: 100px;
    color: #000;
    font-size: 16px;
  }
  
 .no-payments{
    border: 1px solid rgba(0,0,0,0.16);
    font-size: 11.5px;
    padding: 16px 16px;
    border-radius: 32px;
    color: #000;
 }
  
 .ticket__title{
    border-bottom: 1.5px dashed rgba(0,0,0,0.24);
 }
  .ticket__title p{
    height: 64px;
display: flex;
align-items: center;
justify-content: center;
font-weight: 500;
margin-right: 16px;
}
.ticket__title p svg{
    margin-right: 16px;
}

  
  .ticket__content form{
    padding: 16px 16px;
  }
  .ub-btrr_4px{
    border-radius: 100px;
  }
  .ub-btrl_4px{
    border-radius: 100px;
  }
  .payment-sale{
    position: relative;
    margin-bottom: 16px;
  }
.payment-sale span{
    display: block;
    font-size:11.5px;
    margin-top: 4px;
    white-space: wrap;
    text-indent: 16px;
}

.payment-sale span::before{
    content:'';
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    background: #C7D2A0;
    border-radius: 50px;
}
.payment-sale{
    border-top: 1.5px dashed rgba(0,0,0,0.24);
    padding-top: 16px;
}

`


const Modal = ({ showModal, setShowModal, description }) => {

    const [landing, setLanding] = useState([]);

    useEffect(() => {
        axios.get('https://reps-club.com:8000/v1/landing')
            .then(response => {
                setLanding(response?.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const [email, setEmail] = useState('');
    const [isPaymentNonRefundable, setIsPaymentNonRefundable] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email || !email.includes('@')) {

            toaster.notify('Вы не указали email!', {
                id: 'forbidden-action',
            })
            return;
        }

        if (!isPaymentNonRefundable) {
            toaster.notify('Оплата возможно только при условии согласия!', {
                id: 'forbidden-action',
            })
            return;
        }

        const formData = {
            email,
            isPaymentNonRefundable,
            description,
            price: landing.map(item => (item.price))
        };

        axios
            .post('https://reps-club.com:8000/v1/create-branch', formData)
            .then((response) => {
                const { payment_url, payment_id } = response.data;
                localStorage.setItem('payments', payment_id);
                //console.log(payment_url);
                location.assign(payment_url); // or
            })
            .catch((error) => {

                console.error(error);
            });
    };

    return (
        <StyledDiv>
            <div className={showModal ? 'modal-branch' : 'modal-branch hidden'}>
                <div className="modal-branch-content">
                    <span className="close" onClick={() => setShowModal(false)}>
                        &times;
                    </span>

                    <div className="ticket">
                        <div className='ticket__title'>
                            <p><Ticket strokeWidth={2} size={24} /> Покупка билета</p>
                        </div>
                        <div className="ticket__content">
                            <form onSubmit={handleSubmit}>
                                <TextInputField
                                    label={'Ваша почта'}
                                    inputHeight={'40px'}
                                    placeholder='Ваша почта'
                                    description={'На почту придет вся информация о событии'}
                                    marginBottom={16}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <p className='no-payments'>Подтвердите, что Вы согласны с тем, что отсутствует возможность возврата денежных средств после покупки билета!</p>
                                <Checkbox
                                    label="Согласна/(ен)"
                                    checked={isPaymentNonRefundable}
                                    onChange={(e) => setIsPaymentNonRefundable(e.target.checked)}
                                />
                                <p className='payment-sale'>
                                    Текущая стоимость билета: {landing.map(item => (item.price))} ₽
                                    <span>Если вы являетесь участником клуба и указали почту на которой действует подписка основных тарифов, стоимость будет снижена на 40%</span>
                                </p>
                                <div className='payment-block'>
                                    <Button width={'100%'} height={48} appearance='primary' type="submit">Купить билет</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </StyledDiv>
    );
};

export default Modal;
