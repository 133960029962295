import React, { useEffect, useState } from 'react';
import '../styles/main.css';
import '../styles/lk.css';
import '../styles/flexboxgrid.min.css';
import HeaderLeft from '../components/headerLeft'
import { TextInputField, Textarea, Label, Pane, Button, toaster, InlineAlert, Badge } from 'evergreen-ui';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';



function AnketaPage(props) {
    const data = props.data;
    const profile = props.profile;

    const navigate = useNavigate();

    if (profile === null) {
        window.location.replace('/lk')
    }




    const date = Date();
    const [linkSubscribe, setSubscribe] = useState(false)
    const [activeTab, setActiveTab] = useState(0);
    const [profilePicture, setProfilePicture] = useState("");
    const [profileFio, setProfileFio] = useState("");
    const [profileMail, setProfileMail] = useState("");
    const [profileTelegram, setProfileTelegram] = useState("");
    const [profileSite, setProfileSite] = useState("");
    const [profileAbout, setProfileAbout] = useState("");
    const [profileRequest, setProfileRequest] = useState("");
    const [profileLogin, setProfileLogin] = useState("");

    const [profilePassword, setProfilePassword] = useState("");
    const [currentPassword, setProfileCurrentPassword] = useState("");

    const [inputPicture, setPicture] = useState(false)


    useEffect(() => {
        let imgLink = profile.photo !== undefined ? (profile.photo) : (null)
        setProfilePicture(imgLink);
        setProfileFio(profile.fio);
        setProfileMail(data.email);
        setProfileTelegram(profile.telegram);
        setProfileSite(profile.siteLink);
        setProfileAbout(profile.myAbout);
        setProfileRequest(profile.myRequest);

    }, [profile, data])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'userFio':
                setProfileFio(value);
                break;
            case 'userSite':
                setProfileSite(value);
                break;
            case 'userTelegram':
                setProfileTelegram(value);
                break;
            case 'userAbout':
                setProfileAbout(value);
                break;
            case 'userRequest':
                setProfileRequest(value);
                break;
            case 'userPass':
                setProfilePassword(value);
                break;
            case 'userLogin':
                setProfileLogin(value);
                break;
            case 'userEmail':
                setProfileMail(value);
                break;
            case 'userCurrentPass':
                setProfileCurrentPassword(value);
                break;
            default:
                break;
        }
    };







    const handleChangePicture = async () => {
        try {

            const formData = new FormData();
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/*';

            const file = await new Promise((resolve) => {
                fileInput.addEventListener('change', (event) => {
                    resolve(event.target.files[0]);
                });
                fileInput.click();
            });
            if (file) {
                setProfilePicture(URL.createObjectURL(file))
                const newImageUrl = URL.createObjectURL(file);
                const avatarElement = document.querySelector('.avatar');
                avatarElement.src = newImageUrl;

                formData.append('avatar', file);
                await axios.put('https://reps-club.com:8000/v1/setting/avatar', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': localStorage.getItem("accessToken")
                    }
                }).then((response) => {

                    toaster.success('Ооо. У вас теперь новый аватар :)', {
                        id: 'forbidden-action',
                    })

                    //newimg(file)

                })
                    .catch((error) => {
                        toaster.danger('Упс! Что-то пошло не так :(', error, {
                            id: 'forbidden-action',
                        })
                    });
            }

        } catch (error) {
            console.error(error);
        }
    };





    const handleRemovePicture = () => {

        axios.delete('https://reps-club.com:8000/v1/setting/del/avatar', {
            headers: {
                'x-access-token': localStorage.getItem("accessToken")
            }
        }).then((response) => {
            const newImageUrl = "";
            const avatarElement = document.querySelector('.avatar > img');
            avatarElement.src = newImageUrl;
            toaster.success('Вы успешно удалили свой аватар!', {
                id: 'forbidden-action',
            });

        })
            .catch((error) => {
                console.log(error, 'error')
            });


    };

    const handleSubmit = (e) => {
        e.preventDefault();

        try {

            axios.put('https://reps-club.com:8000/v1/setting/profile', {
                profileFio: profileFio,
                profileSite: profileSite,
                profileTelegram: profileTelegram,
                profileAbout: profileAbout,
                profileRequest: profileRequest

            }, {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then((response) => {
                toaster.success('Данные успешно обновлены', {
                    id: 'forbidden-action',
                })
            })
                .catch((error) => {
                    toaster.danger('Упс! Что-то пошло не так :(', {
                        id: 'forbidden-action',
                    })
                });
        } catch (error) {
            console.error(error);
        }

    }


    const handleEditPassword = (e) => {
        e.preventDefault();

        try {

            axios.put('https://reps-club.com:8000/v1/setting/password', {
                currentPassword: currentPassword,
                password: profilePassword,

            }, {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then((response) => {
                toaster.success('Данные успешно обновлены', {
                    id: 'forbidden-action',
                })
            })
                .catch((error) => {
                    toaster.danger('Упс! Что-то пошло не так :(', {
                        id: 'forbidden-action',
                    })
                });
        } catch (error) {
            console.error(error);
        }

    }

    const isValidTelegramLogin = (login) => {
        const regex = /^[a-zA-Z0-9_-]+$/; // Регулярное выражение для проверки логина Telegram
        return regex.test(login);
    };

    // Пример использования



    const handleEditLogin = (e) => {
        e.preventDefault();

        try {
            if (isValidTelegramLogin(profileLogin)) {
                axios.put('https://reps-club.com:8000/v1/setting/username', {
                    login: profileLogin,

                }, {
                    headers: {
                        'x-access-token': localStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    toaster.success('Данные успешно обновлены', {
                        id: 'forbidden-action',
                    })
                })
                    .catch((error) => {
                        toaster.danger('Упс! Что-то пошло не так :(', {
                            id: 'forbidden-action',
                        })
                    });
                }else{
                    toaster.danger('Логин не соответствует требованиям!', {
                        id: 'forbidden-action',
                    })
                }
            } catch (error) {
                console.error(error);
            }
       

    }

    const handleEditEmail = (e) => {
        e.preventDefault();

        try {

            axios.put('https://reps-club.com:8000/v1/setting/email', {
                email: profileMail,

            }, {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then((response) => {
                toaster.success('Данные успешно обновлены', {
                    id: 'forbidden-action',
                })
            })
                .catch((error) => {
                    toaster.danger('Упс! Что-то пошло не так :(', {
                        id: 'forbidden-action',
                    })
                });
        } catch (error) {
            console.error(error);
        }

    }


    const createPayment = async (email, price) => {
        try {

            const response = await axios.post('https://reps-club.com:8000/v1/create-payment', {
                "price": getPriceByOption(parseInt(price)),
                "tarif": parseInt(price),
                "email": email
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })


            const { payment_url } = response.data;

            // console.log(payment_url, paymentId);
            // Open the confirmation URL in a new window
            location.assign(payment_url);
          //  window.open(payment_url, '_blank');
        } catch (error) {
            console.error(error);
        }
    };


    const getPriceByOption = (option) => {
        if (option === 1) {
            return '18000.00';
        }
        if (option === 2) {
            return '34000.00';
        }
        if (option === 3) {
            return '60000.00';
        }
    }

    const TruncatedName = (fullName) => {
        // Разделяем полное имя на отдельные слова
        if (fullName !== undefined) {
            const words = fullName.split(' ');
            // Определяем первую букву фамилии и имени
            const truncatedLastName = words[0]?.charAt(0);
            const truncatedFirstName = words[1]?.charAt(0);
            const sliceName = truncatedLastName + '' + truncatedFirstName;

            return sliceName
        } else {
            return 'NN'
        }
    }
    const handleTabClick = (index) => {
        setActiveTab(index);
    };



    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('ru-RU', options);
    }



    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Мой профиль</p>
                            </div>
                            <div className='lk-setting-anketa'>
                                <div className="setting-card-tab-menu">
                                    <p className={` ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>Настройки профиля</p>
                                    <p className={` ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>Настройки безопасности</p>

                                </div>
                                {activeTab === 0 &&
                                    <form className='setting-form' onSubmit={handleSubmit}>
                                        <p className='setting-form-title'>Основная информация</p>
                                        <div className='fl-edit-avatar' style={{ width: '100%' }}>
                                            <div className='fl-custom-avatar'>
                                                <div className="avatar">
                                                    {profilePicture ? (
                                                        <img src={profilePicture} alt={TruncatedName(profile.fio)} />
                                                    ) : (
                                                        <div className="initials">{TruncatedName(profile.fio)}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <InlineAlert intent="none" marginBottom={16} marginTop={16} width={'100%'} fontSize={12}>
                                                {new Date(data.end_subscribe) > new Date(date) ? 'подписка до ' + formatDate(data.end_subscribe) : <div>подписка не активна! <Badge color="blue" marginRight={8} style={{ cursor: 'pointer' }} onClick={() => setSubscribe(true)}>Оплатить</Badge></div>}
                                            </InlineAlert>
                                            {
                                                linkSubscribe &&
                                                <div style={{display: 'flex'}}>
                                                <p onClick={() => createPayment(data.email, 1)}>18 000 рублей, 3 мес</p>
                                                <p onClick={() => createPayment(data.email, 2)}>34 000 рублей, 6 мес</p>
                                                <p onClick={() => createPayment(data.email, 3)}>60 000 рублей, 12 мес</p>
                                                </div>
                                            }
                                            <div className='fl-edit-avatar-elem'>
                                                <p onClick={handleChangePicture}>Изменить фотографию</p>
                                                <p onClick={handleRemovePicture}>Удалить фото</p>
                                            </div>
                                        </div>
                                        <div className='form-line'>
                                            <TextInputField
                                                label="Ваше ФИО"
                                                description="Пожалуйста, указывайте достоверные сведения"
                                                placeholder="Фамилия Имя Отчество"
                                                inputHeight={48}
                                                // value={profileFio}
                                                onChange={handleInputChange}
                                                name="userFio"
                                                defaultValue={profileFio}
                                            />
                                        </div>

                                        <div className='form-line'>
                                            <TextInputField
                                                label="Ссылка на ваш сайт"
                                                description="Можете указывать без https:// или с ним"
                                                placeholder="Адрес сайта"
                                                inputHeight={48}
                                                // value={profileSite}
                                                onChange={handleInputChange}
                                                name="userSite"
                                                defaultValue={profileSite}
                                            />
                                            <TextInputField
                                                label="Ваш Telegram"
                                                description="Его можно найти в профиле telegram"
                                                placeholder="Ваше имя пользователя в Telegram"
                                                inputHeight={48}
                                                // value={profileTelegram}
                                                onChange={handleInputChange}
                                                name="userTelegram"
                                                defaultValue={profileTelegram}
                                            />
                                        </div>
                                        <div className='form-line'>
                                            <Pane marginBottom={16}>
                                                <Label htmlFor="textarea-1" marginBottom={8} display="block">
                                                    О вас
                                                </Label>
                                                <Textarea id="textarea-1" placeholder="О вас" style={{ fontSize: '16px', lineHeight: '24px' }}
                                                    // value={profileAbout}
                                                    onChange={handleInputChange}
                                                    name="userAbout"
                                                    defaultValue={profileAbout}
                                                />
                                            </Pane>
                                            <Pane marginBottom={16}>
                                                <Label htmlFor="textarea-2" marginBottom={8} display="block">
                                                    Что вы хотите получить
                                                </Label>
                                                <Textarea id="textarea-2" placeholder="Что вы хотите получить" style={{ fontSize: '16px', lineHeight: '24px' }}
                                                    //value={profileRequest}
                                                    defaultValue={profileRequest}
                                                    onChange={handleInputChange}
                                                    name="userRequest"
                                                />
                                            </Pane>
                                        </div>
                                        <Button marginRight={16} appearance="primary" height={48}>Сохранить изменения</Button>
                                    </form>
                                }
                                {activeTab === 1 &&
                                    <div className='setting-form'>
                                        <p className='setting-form-title'>Настройки безопасности</p>
                                        <p style={{ marginBottom: '16px', fontSize: '20px' }}>Изменение логина:</p>

                                        <TextInputField
                                            label="Ваш новый логин"
                                            description={"Минимальная длина 5 символов. Разрешены символы от a-z и подчеркивания" + " Ваш текущий логин: " + data.username}
                                            placeholder="Ваш новый логин"
                                            inputHeight={48}
                                            onChange={handleInputChange}
                                            name="userLogin"
                                            width={'256px'}
                                            minLength={5}
                                            maxLength={15}
                                            value={profileLogin}

                                        />

                                        <Button marginBottom={16} appearance="primary" height={48} onClick={handleEditLogin}>Изменить логин</Button>


                                        <div className='form-line'>
                                            <TextInputField
                                                label="Ваш email"
                                                type='email'
                                                description="Указывайте достоверную почту!"
                                                placeholder="email"
                                                inputHeight={48}
                                                // value={profileFio}
                                                onChange={handleInputChange}
                                                name="userEmail"
                                                defaultValue={profileMail}
                                            />
                                        </div>
                                        <Button marginBottom={16} appearance="primary" height={48} onClick={handleEditEmail}>Изменить почту</Button>



                                        <p style={{ marginBottom: '16px', fontSize: '20px' }}>Изменение пароля:</p>
                                        <div className='form-line'>
                                            <TextInputField
                                                label="Ваш текущий пароль"
                                                description=""
                                                placeholder="Ваш текущий пароль"
                                                inputHeight={48}
                                                value={currentPassword}
                                                onChange={handleInputChange}
                                                name="userCurrentPass"
                                                type='password'

                                            />
                                            <TextInputField
                                                label="Новый пароль"
                                                description=""
                                                placeholder="Ваш новый"
                                                inputHeight={48}
                                                value={profilePassword}
                                                onChange={handleInputChange}
                                                name="userPass"
                                                type='password'

                                            />
                                            <TextInputField
                                                label="Повторите новый пароль"
                                                description=""
                                                placeholder="Ваш новый пароль"
                                                inputHeight={48}
                                                //value={profileFio}
                                                //onChange={handleInputChange}
                                                name="userPassVerify"
                                                type='password'

                                            />
                                        </div>
                                        <Button marginBottom={16} appearance="primary" height={48} onClick={handleEditPassword}>Изменить пароль</Button>
                                    </div>
                                }
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </div >
    )
}
export default AnketaPage