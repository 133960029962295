import React, { useState, useEffect, lazy } from 'react';
import Marquee from "react-fast-marquee";
import '../styles/main.css';
import '../styles/lk.css';
import '../styles/flexboxgrid.min.css';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import HeaderLeft from '../components/headerLeft'
import { Swiper, SwiperSlide } from 'swiper/react';

import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRight, } from 'akar-icons';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import {
    TextInputField,
    Button,
    toaster,
    TextareaField,
} from 'evergreen-ui';


import imgStep from '../content/img/step-img.svg'
import LazyLoad from 'react-lazyload';
import { Navigation, Pagination } from 'swiper';



const Calendar = lazy(() => import('../components/calendar3'));

function CabinetPage(props) {

    const data = props.data;
    const profile = props.profile;
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        setIsShown(!profile);
    }, [profile]);

    const navigate = useNavigate();

    const [step, setStep] = useState(1); // начинаем с первого шага
    const [dataMounth, setDataMounth] = useState([]);
    const [dataEvents, setDataEvents] = useState([]);
    const [username, setUsername] = useState('');
    const botToken = '6246505928:AAGJRRJeJS1wqjpdDHv4Py91iBNFxv2KpYA'
    const groupId = '-1001972348888';
    const [inviteLink, setInviteLink] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchCounrty, setSearchCountry] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showCityList, setShowCityList] = useState(false);
    const [dataAlphabet, setDataAlphabet] = useState([]);
    const [dataAlphabet2, setDataAlphabet2] = useState([]);
    const [fio, setFullName] = useState("");
    const [website, setWebsite] = useState("");
    const [socialMedia, setSocialMedia] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [photo, setPhoto] = useState("https://img.freepik.com/free-vector/vintage-spring-flower-illustration-remixed-from-public-domain-artworks_53876-115443.jpg?w=2000");
    const [myAbout, setMyAbout] = useState("");
    const [myRequest, setMyRequest] = useState("");



    const [files, setFiles] = React.useState([])
    const [fileRejections, setFileRejections] = React.useState([])
    const handleChange = React.useCallback((files) => setFiles([files[0]]), [])
    const handleRejected = React.useCallback((fileRejections) => setFileRejections([fileRejections[0]]), [])
    const handleRemove = React.useCallback(() => {
        setFiles([])
        setFileRejections([])
    }, [])


const numberYear = new Date().getFullYear();




    useEffect(() => {
        if (isShown) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [isShown]);

    const handleNext = () => {
        setStep(step + 1); // увеличиваем шаг на 1
    };

    const handlePrev = () => {
        setStep(step - 1); // уменьшаем шаг на 1
    };

    useEffect(() => {
        axios.get('https://reps-club.com:8000/v1/mounth')
            .then(response => {
                // console.log(response.data);
                setDataMounth(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('https://reps-club.com:8000/v1/mounth/events')
            .then(response => {
                setDataEvents(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const handleInvite = async () => {
        try {
            const getInviteLink = async () => {
                try {
                    const response = await axios.get(`https://api.telegram.org/bot${botToken}/exportChatInviteLink?chat_id=${groupId}`);
                    setInviteLink(response.data.result);
                } catch (error) {
                    console.error(error);
                }
            };
            getInviteLink();
        } catch (err) {
            console.log(err);
        }
    };

    const handleResultClick = (result) => {

        setTimeout(() => {
            setShowCityList(false);
            setSearchResults([]);
        }, 100);
        setCountry(result.country);
        setCity(result.city)

        setSearchTerm(result.city);
        setSearchCountry(result.country);

    };

    const handleSave = (event) => {

        event.preventDefault();
        axios.post("https://reps-club.com:8000/v1/user/anketa", {
            fio: fio,
            siteLink: website,
            socialLink: socialMedia,
            photo: photo,
            myCountry: country,
            myCity: city,
            telegram: username,
            myAbout: myAbout,
            myRequest: myRequest
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem("accessToken")
            }
        })
            .then((response) => {
                setIsShown(false);
                toaster.success('Ура 🥳 Теперь у Вас есть профиль!', {
                    id: 'forbidden-action',
                })
            })
            .catch((error) => {
                toaster.danger('Что-то пошло не так, попробуйте обновить страницу', {
                    id: 'forbidden-action',
                })
            });


    };

    const fetchSearchResults = async (searchTerm) => {
        try {
            if (searchTerm.length >= 3) {
                const response = await axios.get(`https://reps-club.com:8000/v1/city`, { params: { city: searchTerm } });
                if (response.data.cities.length === 0) {
                    setShowCityList(false);
                } else {
                    setSearchResults(response.data.cities);
                    setShowCityList(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (searchTerm !== '') {
            fetchSearchResults(searchTerm);
        } else {
            fetchSearchResults([]);
        }
    }, [searchTerm]);


    useEffect(() => {
        axios.get(`https://reps-club.com:8000/v1/videos/alphabet/1`, {
            headers: {
                'x-access-token': localStorage.getItem("accessToken")
            }
        })
            .then(response => {
                setDataAlphabet(response.data);
            })
            .catch(error => {
                console.log('Ошибка получения видеороликов')
            });
    }, []);

    useEffect(() => {
        axios.get(`https://reps-club.com:8000/v1/videos/alphabet/2`, {
            headers: {
                'x-access-token': localStorage.getItem("accessToken")
            }
        })
            .then(response => {
                setDataAlphabet2(response.data);
            })
            .catch(error => {
                console.log('Ошибка получения видеороликов')
            });
    }, [])
    const currentYear = new Date().getFullYear();

    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-calendar'>
                            <div className='lk-section-title'>
                                <p>Календарь событий</p>
                            </div>
                            <div className='lk-calendar-slider'>

                                <Swiper
                                    pagination={{
                                        type: 'progressbar',
                                    }}
                                    navigation={true}
                                    modules={[Pagination, Navigation]}

                                    freeMode={true}
                                    slidesPerView={'auto'}
                                    draggable={true}
                                    spaceBetween={24}
                                    initialSlide={0}
                                    // navigation={false}
                                    className="mySwiper"
                                >

                                    {dataMounth.map(item => (
                                        <SwiperSlide key={item.idCalendar}>
                                            <div className='lk-item-calendar'>
                                                <LazyLoadComponent visibleByDefault={false} placeholder={
                                                    <Skeleton width={'100%'} height={'100%'} count={5} />
                                                }>
                                                    <Calendar month={item.mounthNumber} year={numberYear} events={dataEvents} />
                                                </LazyLoadComponent>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                        </section>
                        <section className='lk-section-alphabet'>
                            <div className='lk-section-title-2'>
                                <p>Шторная азбука</p>
                            </div>
                            <div className='lk-alphabet-slider'>
                                {dataAlphabet.length > 0 ?
                                    <Swiper
                                        freeMode={true}
                                        slidesPerView={'auto'}
                                        draggable={true}
                                        spaceBetween={24}
                                        initialSlide={0}
                                        navigation={false}
                                        className="mySwiper"
                                    >
                                        {
                                            dataAlphabet.map((video, index) =>
                                            (
                                                <SwiperSlide key={index} onClick={() => navigate('/lk/video/' + video.id)}>
                                                    {index <= 1 &&
                                                        <div className='label-new-video'>
                                                            <span>новое</span>
                                                        </div>
                                                    }
                                                    <div className='lk-item-alphabet'>
                                                        <LazyLoadImage

                                                            effect="blur"
                                                            src={'https://reps-club.com:8000' + video.preview}
                                                            alt={video.title}
                                                            key={index}
                                                            width={'100%'}
                                                            height={'100%'}
                                                        />
                                                        {/* <img src={'http://194.67.86.147' + video.preview} alt={video.title} /> */}
                                                        <p className='text-alphabet-slider'><Marquee speed={16} pauseOnHover={true}>{video.title}</Marquee></p>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                    : <p className='lk-alphabet-none'>Видеоролики еще не добавили</p>}

                                <Link to={'/lk/video/grid/1'} style={{ marginTop: '24px', textDecoration: 'none', display: 'flex', alignItems: 'center', border: '1.5px solid black', color: '#000', padding: '8px 16px', borderRadius: '100px 100px' }}>Посмотреть все ролики <ArrowRight style={{ marginLeft: '8px' }} strokeWidth={2} size={20} /></Link>
                            </div>
                        </section>
                        <section className='lk-section-banner'>
                            <LazyLoad placeholder={<Skeleton count={5} />}>
                                <div className='lk-banner-content' style={{ background: '#FFEBF4' }}>
                                    <div className='lk-banner-text'>
                                        <h2><span style={{ fontSize: '16px', display: 'block', fontWeight: '400' }}>Получи грант</span> Reps Club {currentYear}</h2>
                                        <a href='/lk/grant'>Принять участие</a>
                                    </div>
                                </div>
                            </LazyLoad>
                        </section>
                        <section className='lk-section-alphabet'>
                            <div className='lk-section-title-2'>
                                <p>Шторная мудрость</p>
                            </div>
                            <div className='lk-alphabet-slider'>
                                {dataAlphabet2.length > 0 ?
                                    <Swiper
                                        freeMode={true}
                                        slidesPerView={'auto'}
                                        draggable={true}
                                        spaceBetween={24}
                                        initialSlide={0}
                                        navigation={false}
                                        className="mySwiper"
                                    >
                                        {
                                            dataAlphabet2.map((video, index) =>
                                            (
                                                <SwiperSlide key={index} onClick={() => navigate('/lk/video/' + video.id)}>
                                                    {index <= 1 &&
                                                        <div className='label-new-video'>
                                                            <span>новое</span>
                                                        </div>
                                                    }
                                                    <div className='lk-item-alphabet'>
                                                        {/* <img src={'http://194.67.86.147' + video.preview} alt={video.title} /> */}
                                                        <LazyLoadImage

                                                            effect="blur"
                                                            src={'https://reps-club.com:8000' + video.preview}
                                                            alt={video.title}
                                                            key={index}
                                                            width={'100%'}
                                                            height={'100%'}
                                                        />
                                                        <p className='text-alphabet-slider'><Marquee speed={16} pauseOnHover={true}>{video.title}</Marquee></p>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                    : <p className='lk-alphabet-none'>Видеоролики еще не добавили</p>}
                                <Link to={'/lk/video/grid/2'} style={{ marginTop: '24px', textDecoration: 'none', display: 'flex', alignItems: 'center', border: '1px solid black', color: '#000', padding: '8px 16px', borderRadius: '100px 100px' }}>Посмотреть все ролики <ArrowRight style={{ marginLeft: '8px' }} strokeWidth={2} size={20} /></Link>
                            </div>
                        </section>
                        {isShown ? (
                            <div className="modal">
                                <div className="modal-content">
                                    <div className='lk-anketa-content'>
                                        <div className='step-form-anketa'>

                                            {step === 1 && (
                                                <div className='step-block'>
                                                    <h2>Как здорово, что Вы с нами!</h2>
                                                    <img src={imgStep} alt='step-img-welcom' width={'100%'} />
                                                    <p>
                                                        <b>REPS Club</b> – безопасное и яркое пространство, где хорошо и перспективно, интересно и доброжелательно.
                                                        Здесь любимая работа становится успешным бизнесом и остаётся любимым делом
                                                    </p>
                                                    <p>
                                                        У нас нет конкурентов, только друзья и коллеги.
                                                        И мы <b>вместе</b>. Вживую и онлайн. В разных городах и странах.
                                                        Работаем, учимся, делимся, обретаем и дарим, узнаём и помогаем, заботимся и поддерживаем, вдохновляем и сотрудничаем.
                                                        Всё как в жизни, только бережнее.
                                                    </p>
                                                    <div className='step-block-footer'>
                                                        <Button onClick={handleNext} appearance="primary" height={40}>Следующий шаг</Button>
                                                    </div>
                                                </div>
                                            )}
                                            {step === 2 && (
                                                <div className='step-block'>
                                                    <h2>Шаг {step}: Заполните анкету</h2>
                                                    <div className='step-block-content'>
                                                        <TextInputField label="Фамилия Имя Отчество" inputHeight={40} value={fio} onChange={(e) => setFullName(e.target.value)} />
                                                        <div className='form-input-line'>
                                                            <div className='city-list-input'>
                                                                <TextInputField label="Из какого Вы города ?" inputHeight={40} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                                                {
                                                                    showCityList && (
                                                                        <ul className='city-list'>
                                                                            {searchResults.map((result) => (
                                                                                <li key={result.id} onClick={() => handleResultClick(result)}>
                                                                                    {result.city}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )
                                                                }
                                                            </div>
                                                            <TextInputField label="Страна" inputHeight={40} value={searchCounrty} disabled />
                                                        </div>
                                                        <div className='form-input-line'>
                                                            <TextInputField label="Ссылка на сайт" inputHeight={40} value={website} onChange={(e) => setWebsite(e.target.value)} />
                                                            <TextInputField label="Ссылка на соц. сеть" inputHeight={40} value={socialMedia} onChange={(e) => setSocialMedia(e.target.value)} />
                                                        </div>
                                                        {/* <FilePicker
                                                            label="Загрузи свою фотографию"
                                                            placeholder="Загрузи свою фотографию"
                                                            description="Ты можешь загрузить 1 фотографию размером не более 50МБ."
                                                            maxSizeInBytes={50 * 1024 ** 2}
                                                            height={48}
                                                            maxFiles={1}
                                                            onChange={handleChange}
                                                            onRejected={handleRejected}
                                                            renderFile={(file) => {
                                                                const { name, size, type } = file
                                                                const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
                                                                const { message } = fileRejection || {}
                                                                return (
                                                                    <FileCard
                                                                        height={72}
                                                                        key={name}
                                                                        isInvalid={fileRejection != null}
                                                                        name={name}
                                                                        onRemove={handleRemove}
                                                                        sizeInBytes={size}
                                                                        type={type}
                                                                        validationMessage={message}
                                                                    />
                                                                )
                                                            }}
                                                            values={files}
                                                            marginBottom={24}
                                                        /> */}
                                                        <TextareaField value={myAbout} onChange={(e) => setMyAbout(e.target.value)} style={{ fontSize: 16, lineHeight: '24px' }} label="Расскажите немного о вашей работе: у вас свой шторный салон, или вы вольный декоратор, а может быть вы только пришли в эту профессию и хотите начать с нуля?" placeholder="Расскажите о работе..." />
                                                        <TextareaField value={myRequest} onChange={(e) => setMyRequest(e.target.value)} style={{ fontSize: 16, lineHeight: '24px' }} label="К каким запросом вы пришли в RepsClub, что хотите получить?" placeholder='Какой у вас запрос?' />

                                                    </div>
                                                    <div className='step-block-footer'>
                                                        <Button onClick={handlePrev} marginRight={16} height={40}>Предыдущий шаг</Button>
                                                        <Button onClick={handleNext} appearance="primary" height={40}>Следующий шаг</Button>
                                                    </div>
                                                </div>
                                            )}
                                            {
                                                step === 3 && (
                                                    <div className='step-block'>
                                                        <h2>Шаг {step}: Тут мы общаемся</h2>

                                                        <div className='telegram-step-content'>
                                                            <p>Ваша уникальная ссылка для вступления в закрытую группу.</p>
                                                            <TextInputField inputHeight={40} onChange={(e) => setUsername(e.target.value)} value={username} placeholder='Напишите свой логин telegram' />
                                                            {inviteLink && <p>Перейдите по ссылке и вступите в группу. Следующий шаг будет доступен после вступления в группу</p>}
                                                            <a className='telegram-link-anketa' href={inviteLink} target="_blank" rel="noreferrer">{inviteLink}</a>
                                                            {!inviteLink && <Button onClick={handleInvite} marginTop={32} height={40} appearance="primary">Получть ссылку</Button>}


                                                        </div>
                                                        <div className='step-block-footer'>
                                                            <Button onClick={handlePrev} marginRight={16} height={40}>Предыдущий шаг</Button>
                                                            <Button onClick={handleNext} appearance="primary" height={40}>Следующий шаг</Button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {step === 4 && (
                                                <div className='step-block'>
                                                    <h2>Шаг {step}: Знакомство с личным кабинетом</h2>
                                                    <div className='step-block-content'>
                                                        <p>
                                                            Календарь событий клуба всегда будет оставаться на главном экране. На нем вы можете увидеть перечень прошедших и предстоящих событий клуба. Нажав на предстоящее событие, откроется новое окно с информацией о событии. Если предстоящее событие - это вебинар, в этом же окне вы сможете присоединиться к вебинару.
                                                        </p>
                                                        <p>
                                                            Не смогли присутствовать - не беда, записи всех вебинаров сохраняются, они будут доступны вам в течение 30 дней, увидеть запись можно будет кликнув на прошедшее мероприятие, или на раздел “записи вебинаров”
                                                            Записи прошедших мероприятий, проведенных до вашего присоединения к клубу не доступны.
                                                        </p>
                                                    </div>
                                                    <div className='step-block-footer'>
                                                        <Button onClick={handlePrev} marginRight={16} height={40}>Предыдущий шаг</Button>
                                                        <Button onClick={handleNext} appearance="primary" height={40}>Следующий шаг</Button>
                                                    </div>
                                                </div>
                                            )}
                                            {step === 5 && (
                                                <div className='step-block'>
                                                    <h2>Шаг {step}: продолжаем знакомится с личным кабинетом</h2>
                                                    <div className='step-block-content'>
                                                        <div className='step-block-scroll'>
                                                            <p>
                                                                На главном экране личного кабинета вы увидите следующие разделы:
                                                                шторная азбука - это уроки для тех, кто совсем недавно начал свой путь в сфере текстильного декора, в ней вы найдете уроки и вебинары
                                                            </p>
                                                            <p>
                                                                шторная мудрость - продвинутые уроки для тех, кто хочет повысить свою квалификацию, в ней вы найдете уроки и вебинары
                                                            </p>
                                                            <p>
                                                                Эти разделы доступны членам клуба без ограничений по времени.
                                                            </p>
                                                            <p>
                                                                Мы в RepsClub всегда работаем над улучшением себя и своего бизнеса, поэтому регулярно проводим тематические марафоны, прохождение которых прокачает тот или иной профессиональный навык.
                                                            </p>
                                                            <p>
                                                                Раздел марафон открывает вам доступ к прошедшим вебинарам, заданиям для выполнения и ссылкам на предстоящие вебинары актуального марафона. А ссылка на чат марфона позволит вам не отставать от коллег и в любом момент обратиться к ним или эксперту марафона за советом.
                                                            </p>
                                                            <p>
                                                                Раздел Грант RepsClub позволяет вам фиксировать динамику вашего бизнеса от месяца к месяцу. Победитель марафона, получающий грант, выбирается на основании представленной динамики. Не забывайте заполнять его в конце каждого месяца, и становитесь победителем, получая и грант, и рост бизнеса вместе с RepsClub!
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='step-block-footer'>
                                                        <Button onClick={handlePrev} marginRight={16} height={40}>Предыдущий шаг</Button>
                                                        <Button onClick={handleNext} appearance="primary" height={40}>Следующий шаг</Button>
                                                    </div>
                                                </div>
                                            )}
                                            {step === 6 && (
                                                <div className='step-block'>
                                                    <h2>Шаг {step}: Помощь</h2>
                                                    <div className='step-block-content'>
                                                        <p>
                                                            условия членства, вопросы заморозки и восстановления доступа, любые вопросы по работе системы, контакты для связи.
                                                            Напоминаем о возможности заморозки доступа к клуба, если вы приобрели тариф на 6 или 12 месяцев. До 1 месяца при покупке тарифа на 6 месяцев, и до 2 месяцев при покупке тарифа на год.
                                                            При необходимости в заморозке, обратитесь к разделу <a href='/lk/support'>помощь</a>, указав необходимый срок заморозки - и отдыхайте спокойно 😊 На это время доступ к материалам будет ограничен.
                                                            Вопросы по работе системы всегда можно задать в том же разделе <a href='/lk/support'>помощь</a>.
                                                        </p>
                                                    </div>
                                                    <div className='step-block-footer'>
                                                        <Button onClick={handlePrev} marginRight={16} height={40}>Предыдущий шаг</Button>
                                                        <Button onClick={handleSave} type="submit" appearance="primary" height={40}>Сохранить данные</Button>
                                                    </div>

                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ) : null}
                    </main>
                </div>
            </div>


        </div>

    )


}
export default CabinetPage
