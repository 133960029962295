import React, { useState, useEffect } from 'react';
import {
    Navigate,
    Outlet

} from 'react-router-dom';
import axios from 'axios';
// function PrivateRoute({ path, ...props }) {

//     console.log(props.isAuthenticated, props.isAdmin);
//     if (props.role === 2) {
//         return props.isAuthenticated && props.isAdmin ? (
//             <Outlet />
//         ) : (
//             <Navigate to="/" replace />
//         );
//     }else{
//         return props.isAuthenticated ? (
//             <Outlet />
//         ) : (
//             <Navigate to="/" replace />
//         );
//     }

// }


const PrivateRoute = ({ ...props }) => {
    const isRole = props.role;
    const [isAuth, setIsAuth] = useState();
    const [isAdmin, setIsAdmin] = useState();

    function refreshToken() {
        const refreshToken = localStorage.getItem('refreshToken');
        axios.post('https://reps-club.com:8000/v1/token/refresh', { refreshToken })
            .then(response => {
                localStorage.setItem('accessToken', response.data.accessToken);
                localStorage.setItem('refreshToken', response.data.refreshToken);
            })
            .catch(error => {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                console.error(error);
            });
    }
    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            axios.get('https://reps-club.com:8000/v1/user', {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then(function (res) {
             
                    setIsAuth(res.data.isAuth);
                    setIsAdmin(res.data.isAdmin);
                    return [isAuth, isAdmin];

            }).catch(function () {
                refreshToken()
            })
        } else {
            setIsAuth(false);
            setIsAdmin(false);
        }
    }, [isAuth, isAdmin]);


    if (isAuth === undefined) return null;
    if (isRole) {
        return isAuth === true && isRole === isAdmin ? <Outlet /> : <Navigate to="/lk" />;
    } else {
        return isAuth === true ? <Outlet /> : <Navigate to="/login" />;
    }

}


export default PrivateRoute