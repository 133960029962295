import React, { useState } from 'react';
import '../styles/main.css';
import '../styles/flexboxgrid.min.css';
import Header from '../components/header';
import { Pane, TextInput, Button, Alert } from 'evergreen-ui';
import { useNavigate, redirect } from 'react-router-dom';
import axios from 'axios'

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isReload, setReload] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        if (username.length < 5) {
            setAlertMessage('Имя пользователя не может быть таким коротким');
            return;
        }

        if (password.length < 5) {
            setAlertMessage('Пароль не может быть таким коротким');
            return;
        }

        try {
            const response = await fetch('https://reps-club.com:8000/v1/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                setAlertMessage('Неверный логин или пароль! 😢');
            } else {
                const data = await response.json();
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken);

                window.location.replace('/lk')

            }
        } catch (err) {
            console.error(err);
            setAlertMessage('Ошибка сервиса, повторите еще раз! 😢');
        }
    };


    const [loginOrEmail, setLoginOrEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://reps-club.com:8000/v1/reset-password', {
                loginOrEmail
            });

            setMessage(response.data.message);
            setError('');
        } catch (error) {
            setMessage('');
            setError(error.data);
        }
    };


    return (
        <div className="App">
            <Header />
            <main className='content auth-content'>
                <section className='section-auth'>
                    <h1>{!isReload ? 'Авторизация' : 'Восстановить пароль'}</h1>
                    <Pane maxWidth={400} margin="auto">
                        {alertMessage && <Alert intent="danger" marginBottom={16}>{alertMessage}</Alert>}
                        {
                            !isReload &&
                            < form onSubmit={handleLogin}>
                                <TextInput
                                    required
                                    label="Имя пользователя (логин)"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Логин"
                                    marginBottom={16}
                                    height={48}
                                    width={'100%'}
                                />
                                <TextInput
                                    required
                                    label="Пароль"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Пароль"
                                    marginBottom={16}
                                    height={48}
                                    width={'100%'}
                                />
                                <Button type="submit" appearance="primary" height={48} width={'100%'}>Войти</Button>
                            </form>}
                        {
                            isReload &&
                            <Pane maxWidth={400} margin="auto">
                                  {message &&   <Alert
                                    intent="success"
                                    title={message}
                                    marginBottom={32}
                                />}
                                {error &&   <Alert
                                    intent="danger"
                                    title={error}
                                    marginBottom={32}
                                />}
                                <form onSubmit={handleSubmit}>
                                    <TextInput
                                        value={loginOrEmail}
                                        onChange={(e) => setLoginOrEmail(e.target.value)}
                                        placeholder="Логин или почта"
                                        marginBottom={16}
                                        required
                                        height={48}
                                        width={'100%'}
                                    />
                                    <Button type="submit" appearance="primary" height={48} width={'100%'}>Сбросить пароль</Button>
                                </form> 
                            </Pane>}
                        {
                            !isReload ? (
                                <p style={{ textAlign: 'center', marginTop: '16px', opacity: '0.64', cursor: 'pointer' }} onClick={() => setReload(true)}>Забыл/(а) пароль?</p>
                            ) : (
                                <p style={{ textAlign: 'center', marginTop: '16px', opacity: '0.64', cursor: 'pointer' }} onClick={() => setReload(false)}>Вернуться назад</p>
                            )
                        }
                    </Pane>
                </section>
            </main>
        </div >
    );
};
export default LoginPage