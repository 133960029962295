import React, { useState } from 'react';
import '../../styles/main.css';
import '../../styles/lk.css';
import '../../styles/flexboxgrid.min.css';
import HeaderLeft from '../../components/headerLeft'
import axios from 'axios';


import { SelectField, TextInputField, Button, toaster, TextareaField } from 'evergreen-ui';

function MoneyInputField({ value, onChange }) {
    const handleChange = (event) => {
        // Убираем знак ₽ из введенного значения
        const inputValue = event.target.value.replace('₽', '');

        // Валидация введенного значения (можно добавить свои правила)
        if (/^\d*(\.\d{0,2})?$/.test(inputValue)) {
            // Добавляем знак ₽ к значению
            onChange(inputValue + '₽');
        }
    };

    return (
        <TextInputField
            width={'100%'}
            inputHeight={48}
            label="Сумма"
            description={'Если суммы нет, оставьте 0'}
            value={value}
            onChange={handleChange}
            placeholder="Введите сумму"
        />
    );
}

function AdminNotifyPage(props) {

    const data = props.data;
    const profile = props.profile;



    const [isLoading, setIsLoading] = useState(false);
    const [isProgress, setIsProgress] = useState(0);
    const [videoType, setVideoType] = useState(0);
    // const handleFormSubmit = async (event) => {
    //     event.preventDefault();

    //     const fileInput = document.getElementById('videoFile');
    //     const file = fileInput.files[0];

    //     const formData = new FormData();
    //     formData.append('video', file);

    //     try {
    //         setIsLoading(true);
    //         await axios.post('https://reps-club.com:8000/v1/video', formData);
    //         console.log('Видеоролик успешно загружен на сервер');
    //     } catch (error) {
    //         console.error('Ошибка при загрузке видеоролика', error);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };


    // const handleFormSubmit = async (event) => {
    //     event.preventDefault();

    //     const fileInput = document.getElementById('videoFile');
    //     const file = fileInput.files[0];

    //     const formData = new FormData();
    //     formData.append('video', file);

    //     try {
    //       setIsLoading(true);

    //       const config = {
    //         onUploadProgress: (progressEvent) => {
    //           const percentCompleted = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           setIsProgress(percentCompleted);
    //         },
    //       };
    //       await axios.post('https://reps-club.com:8000/v1/video', formData, config);
    //     } catch (error) {
    //       console.error('Ошибка при загрузке видеоролика', error);
    //     } finally {
    //       setIsLoading(false);
    //     }
    //   };

    const [value, setValue] = useState('0');
    const [videoGroup, setVideoGroup] = useState('');



    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleVideoTypeChange = (e) => {
        setVideoType(e.target.value);
    };
    const handleVideoGroupChange = (e) => {
        setVideoGroup(e.target.value);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const fileInput = document.querySelector('#videoFile');
        const file = fileInput.files[0];


        const fileInput2 = document.querySelector('#previewFile');
        const file2 = fileInput2.files[0];


        const fileInput3 = document.querySelector('#dopFile');
        const file3 = fileInput3.files[0];


      

        const webinarType = document.getElementById('webinarType').value;
        const webinarGroup = document.getElementById('webinarGroup').value;
        const nameVideo = document.getElementById('nameVideo').value;
        const descVideo = document.getElementById('descVideo').value;
        const today = new Date();
        const endDate = new Date();
        endDate.setDate(today.getDate() + 30);

        const formData = new FormData();
        formData.append('video', file);
        formData.append('preview', file2)
        formData.append('dop', file3)

        if (videoType === '3' || videoType === '6') {
            formData.append('title', nameVideo);
            formData.append('webinarType', webinarType);
            formData.append('money', value);
            formData.append('description', descVideo);
            formData.append('startDate', today.toISOString());
            formData.append('endDate', endDate.toISOString());
            formData.append('videoType', webinarType);
            formData.append('videoGroup', 6);
        } else {
            formData.append('title', nameVideo);
            formData.append('webinarType', webinarType);
            formData.append('money', 0);
            formData.append('videoType', webinarType);
            formData.append('description', descVideo);
            formData.append('startDate', today.toISOString());
            formData.append('endDate', today.toISOString());
            formData.append('videoGroup', webinarGroup);
        }

        try {
            setIsLoading(true);

            const config = {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setIsProgress(percentCompleted);

                    if (percentCompleted === 100) {
                        setIsLoading(false);
                    }
                },
            };

            await axios.post('https://reps-club.com:8000/v1/video', formData, config);

            toaster.success('Твое видео успешно загружено', {
                description: 'Видео успешно загружено, но нужно еще время для его обработки (~ 30-40 мин)',
            });
            console.log('Видеоролик успешно загружен на сервер');
        } catch (error) {
            console.error('Ошибка при загрузке видеоролика');
        } finally {
            setIsLoading(false);
        }
    };




    //     try {

    //       const response = await fetch('https://api.yookassa.ru/v3/payments', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization': 'test_tK5CnoOhykwLxr_OpG11oZJ8ikqA-pqYrddcJTJxjmA', // Замените YOUR_API_KEY на ваш ключ API ЮKassa
    //           'Idempotence-Key': 'r4rwgfsvdqw4ty5323wed334', // Замените UNIQUE_ID на уникальный идентификатор
    //         },
    //         body: JSON.stringify({
    //           amount: {
    //             value: 100, // Сумма оплаты в рублях
    //             currency: 'RUB',
    //           },
    //           confirmation: {
    //             type: 'redirect',
    //             return_url: 'https://yourwebsite.com/success', // URL страницы успешной оплаты
    //           },
    //           description: 'Оплата товара', // Описание товара
    //         }),
    //       });

    //       const data = await response.json();
    //       setPaymentUrl(data.confirmation.confirmation_url);
    //     } catch (error) {
    //       console.error('Ошибка при создании ссылки оплаты:', error);
    //     }
    //   };


    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Марафоны и видео</p>
                            </div>

                        </section>
                        <div className='lk-add-video'>
                            <form className='addVideoForm' onSubmit={handleFormSubmit}>
                                <p style={{ marginBottom: '8px' }}>Ваш видеоролик</p>
                                <input type="file" id="videoFile" />
                                <p>Изображение привью для видеоролика</p>
                                <input type="file" id="previewFile"  style={{ marginBottom: '8px' }}/>

                                <p>Файл к ролику</p>
                                <input type="file" id="dopFile" />

                                <TextInputField label={'Название видео'} width={'100%'} inputHeight={48} type="text" maxLength={72} id="nameVideo" marginBottom={16} marginTop={16} placeholder='Придумайте название для видео' />
                                <SelectField id="webinarType" style={{ fontSize: '16px' }} label={'Тип загружаемого видео'} inputHeight={48} marginTop={16} inputWidth={'100%'} value={videoType} onChange={handleVideoTypeChange}>
                                    <option style={{ fontSize: '16px' }} value="1">Шторная азбука</option>
                                    <option style={{ fontSize: '16px' }} value="2">Шторная мудрость</option>
                                    <option style={{ fontSize: '16px' }} value="3">Прошедшие вебинары</option>
                                    <option style={{ fontSize: '16px' }} value="4">Марафон</option>
                                    <option style={{ fontSize: '16px' }} value="5">Премия</option>
                                    <option style={{ fontSize: '16px' }} value="6">Ролики на продажу</option>
                                </SelectField>
                                <SelectField id="webinarGroup" style={{ fontSize: '16px' }} label={'В какой раздел'} inputHeight={48} marginTop={16} inputWidth={'100%'} value={videoGroup} onChange={handleVideoGroupChange}>
                                    <option style={{ fontSize: '16px' }} value="1">«Технологии»</option>
                                    <option style={{ fontSize: '16px' }} value="2">«Продажи и реклама»</option>
                                    <option style={{ fontSize: '16px' }} value="3">«Бухгалтерия и юридические вопросы»</option>
                                    <option style={{ fontSize: '16px' }} value="4">«Дополнительные материалы»</option>
                                    <option style={{ fontSize: '16px' }} value="5">«Кадровый вопрос»</option>
                                    <option style={{ fontSize: '16px' }} value="7">«Премия»</option>
                                </SelectField>
                                <TextareaField label={'Описание к ролику'} width={'100%'} inputHeight={48} type="text"  id="descVideo" marginBottom={16} marginTop={16} placeholder='Краткий текст для видео' />


                                {videoType === '3' || videoType === '6' &&
                                    <div>
                                        <MoneyInputField value={value} onChange={handleChange} />
                                    </div>
                                }
                                {videoType === '3' &&
                                    <div>
                                        <TextInputField disabled label={'Ролик будет доступен с'} width={'100%'} inputHeight={48} type="date" id="startDate" marginBottom={16} defaultValue={new Date().toISOString().substr(0, 10)} />
                                        <TextInputField disabled label={'Ролик будет доступен по'} width={'100%'} inputHeight={48} type="date" id="endDate" marginBottom={16} defaultValue={new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10)} />
                                    </div>
                                }
                                <Button width={'100%'} type="submit" isLoading={isLoading} height={48} appearance='primary'>
                                    {isLoading ? ('Идет загрузка видеоролика, осталось ' + isProgress + '%') : ('Загрузить видео')}
                                </Button>
                            </form>

                        </div>
                    </main>
                </div>
            </div >
        </div >
    )
}
export default AdminNotifyPage