import React, { useState, useEffect } from 'react';
import '../styles/main.css';
import '../styles/lk.css';
import '../styles/flexboxgrid.min.css';
import HeaderLeft from '../components/headerLeft'
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowDown, Heart, Sun, Sparkles, Play } from 'akar-icons';
import { LazyLoadImage} from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/blur.css';

const icons = [
    Heart,
    Sun,
    Sparkles,
];



function PremiaPage(props) {
    const navigate = useNavigate();
    const data = props.data;
    const profile = props.profile;

    let { id } = useParams();
    const [randomIcon, setRandomIcon] = useState(null);
    const [openBlock, setOpenBlock] = useState(7);
    const [videoData, setVideo] = useState([]);

    const handleClick = (index) => {
        if (openBlock === index) {
            setOpenBlock(null);
        } else {
            setOpenBlock(index);
        }
    }


    const getRandomIcon = () => {
        if (!randomIcon) {
            const randomIndex = Math.floor(Math.random() * icons.length);
            const RandomIcon = icons[randomIndex];
            setRandomIcon(<RandomIcon strokeWidth={1.24} size={24} />); // Установите нужный размер иконки
        }
    };


    const [groupedVideos, setGroupedVideos] = useState([]);

    useEffect(() => {
        fetch(`https://reps-club.com:8000/v1/videos/group/5`, {
            headers: {
                'x-access-token': localStorage.getItem("accessToken")
            }
        })
            .then(response => response.json())
            .then(data => setGroupedVideos(data))
            .catch(error => console.error('Error retrieving videos:', error));
        getRandomIcon();
    }, []);

    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Премия</p>
                            </div>
                            <div className='lk-video-folder-content'>
                                {groupedVideos.length > 0 ?
                                    groupedVideos.map(groupedVideo => (
                                        <div key={groupedVideo.group.idGroup} className='video-folder'>
                                            <div className='video-folder-header' onClick={() => handleClick(groupedVideo.group.idGroup)}>
                                                <p className='video-folder-title'><span>{randomIcon} </span> Видеоматериалы</p>
                                                <p className='faq-button-item'><ArrowDown strokeWidth={2} size={32} /></p>
                                            </div>
                                            {openBlock === groupedVideo.group.idGroup ?
                                                <div className='video-folder-content'>
                                                    <div className='row-flex video-folder-items'>
                                                        {groupedVideo.videos.map(video => (
                                                            <div key={video.id} className='col-md-4' onClick={() => navigate('/lk/video/' + video.id)}>
                                                                <div className='video-folder-prev'>
                                                                    <div className='video-folder-prev-play'>
                                                                        <div className='svg-btn' style={{zIndex: 2}}>
                                                                            <Play strokeWidth={1} size={36} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='video-folder-poster'>
                                                                        <LazyLoadImage

                                                                            effect="blur"
                                                                            src={'https://reps-club.com:8000' + video.preview}
                                                                            alt={video.title}
                                                                            key={video.id}
                                                                            width={'100%'}
                                                                            height={'100%'}

                                                                        />
                                                                        {/* <img src={'https://reps-club.com:8000' + video.preview} loading='lazy' alt='test' /> */}
                                                                    </div>
                                                                    <div className='video-prev-title'>
                                                                        <p>{video.title}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    )) :
                                    <div className='none-project'>
                                        <p>Нам очень жаль, но данные еще не загружены! 😔</p>
                                    </div>
                                }
                            </div>
                        </section>

                    </main>
                </div>
            </div>
        </div>
    )
}
export default PremiaPage