import React, { useState, useEffect } from 'react';
import '../../styles/main.css';
import '../../styles/lk.css';
import '../../styles/flexboxgrid.min.css';
import HeaderLeft from '../../components/headerLeft'
import axios from 'axios';
import { Button, TextInputField, toaster, Table, Badge } from 'evergreen-ui';
import { TrashCan } from 'akar-icons';



function CalendarPage(props) {
    const data = props.data;
    const profile = props.profile;

    const [dataEvents, setDataEvents] = useState([]);
    const [dataMounth, setDataMounth] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedOptionEvent, setSelectedOptionEvent] = useState("");
    const [selectedCalendar, setSelectedCalendar] = useState(0);
    const [dateEvent, setDateEvent] = useState('');
    const [description, setDescription] = useState('');
    const [linkEvent, setLinkEvent] = useState('');

    useEffect(() => {
        axios.get('https://reps-club.com:8000/v1/mounth')
            .then(response => {
                // console.log(response.data);
                setDataMounth(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);



    const getMonthName = (monthNum) => {
        const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
        return monthNames[monthNum - 1];
    }




    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const handleOptionChangeType = (event) => {
        setSelectedOptionEvent(event.target.value);
    };




    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post("https://reps-club.com:8000/v1/update/mounth", {
            "idCalendar": parseInt(selectedCalendar),
            "mounthNumber": parseInt(selectedOption)
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                toaster.success('Месяц изменен на ' + getMonthName(parseInt(selectedOption)), {
                    id: 'forbidden-action',
                })
                axios.get('https://reps-club.com:8000/v1/mounth')
                    .then(response => {
                        setDataMounth(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                toaster.danger('Что-то пошло не так, попробуйте обновить страницу', {
                    id: 'forbidden-action',
                })
            });
    }

    const handleSubmitAddEvent = (event) => {
        event.preventDefault();
        axios.post("https://reps-club.com:8000/v1/add/event", {
            "typeEvent": parseInt(selectedOptionEvent),
            "dateEvent": dateEvent,
            "description": description,
            "linkEvent": linkEvent
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem("accessToken")
            }
        })
            .then((response) => {
                toaster.success('Событие успешно добавлено', {
                    id: 'forbidden-action',
                })
            })
            .catch((error) => {
                toaster.danger('Что-то пошло не так, попробуйте обновить страницу', {
                    id: 'forbidden-action',
                })
            });
    }
    useEffect(() => {
        axios.get('https://reps-club.com:8000/v1/mounth/events')
            .then(response => {
                setDataEvents(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const updateEvents = () => {
        axios.get('https://reps-club.com:8000/v1/mounth/events')
            .then(response => {
                setDataEvents(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const delEvent =  (id) => {
        if (localStorage.getItem('accessToken') !== null) {
             axios.delete(`https://reps-club.com:8000/v1/del/event/${id}`, {
                headers: {
                    'x-access-token': localStorage.getItem("accessToken")
                }
            }).then(function (res) {
                toaster.success('Событие календаря удалено!', {
                    id: 'forbidden-action',
                })
                updateEvents();

            })
        } else {
            toaster.danger('Ошибка! Попробуйте обновить страницу', {
                id: 'forbidden-action',
            })
        }
    }

    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Редактор календарей</p>
                            </div>
                            <div className='lk-admin-calendar-content'>
                                <div className='lk-admin-setting-calendar'>
                                    {dataMounth.map(item => (
                                        <div key={item.idCalendar}>
                                            <p className='setting-calendar-link'>Текущий месяц: {getMonthName(parseInt(item.mounthNumber))}</p>
                                            <form method='post' className='mounthEdit' onSubmit={handleSubmit}>
                                                <div className='st-calendar-btn-block'>
                                                    <select name="mounthNumber" className='select-mounth' onChange={handleOptionChange}>
                                                        <option value="1" defaultValue>Январь</option>
                                                        <option value="2">Февраль</option>
                                                        <option value="3">Март</option>
                                                        <option value="4">Апрель</option>
                                                        <option value="5">Май</option>
                                                        <option value="6">Июнь</option>
                                                        <option value="7">Июль</option>
                                                        <option value="8">Август</option>
                                                        <option value="9">Сентябрь</option>
                                                        <option value="10">Октябрь</option>
                                                        <option value="11">Ноябрь</option>
                                                        <option value="12">Декабрь</option>
                                                    </select>
                                                    <Button width={'100%'} height={48} intent="primary" onClick={() => setSelectedCalendar(item.idCalendar)}>
                                                        Изменить месяц
                                                    </Button>
                                                </div>

                                            </form>
                                            <form method='post' className='eventAdd' onSubmit={handleSubmitAddEvent}>

                                                <div className='st-calendar-input-block'>
                                                    <label htmlFor="typeEvent" className='my-label'>Тип события</label>
                                                    <select name="typeEvent" className='select-mounth' id="typeEvent" onChange={handleOptionChangeType}>
                                                        <option value="1" defaultValue>Встреча</option>
                                                        <option value="2">Бранч</option>
                                                        <option value="3">Вебинар</option>
                                                        <option value="4">Практика</option>
                                                    </select>
                                                </div>
                                                <div className='st-calendar-input-block'>
                                                    <TextInputField
                                                        label="Дата события"
                                                        hint="Нельзя добавить несколько событий на одно число"
                                                        type='date'
                                                        name='dateEvent'
                                                        onChange={(e) => setDateEvent(e.target.value)}
                                                        inputHeight={40}
                                                    />
                                                </div>
                                                <div className='st-calendar-input-block'>
                                                    <TextInputField
                                                        label="Описание события"
                                                        hint="Постарайтесь описать коротко"
                                                        placeholder="Напишите описание"
                                                        maxLength={60}
                                                        name='description'
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        inputHeight={40}
                                                    />
                                                </div>
                                                <div className='st-calendar-input-block'>
                                                    <TextInputField
                                                        label="Ссылка на файл или страницу события"
                                                        hint="*Поле не обязательно для заполенения"
                                                        placeholder="Ссылка"
                                                        name='eventLink'
                                                        onChange={(e) => setLinkEvent(e.target.value)}
                                                        inputHeight={40}
                                                    />
                                                </div>
                                                <div className='st-calendar-btn-block'>
                                                    <Button width={'100%'} height={48} appearance="primary">
                                                        Добавить новое событие
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Редактор событий</p>
                            </div>
                            <div className='lk-list-event'>
                                <div>
                                    <Table>
                                        <Table.Head>
                                            <Table.TextHeaderCell>Что за мероприятие?</Table.TextHeaderCell>
                                            <Table.TextHeaderCell>Тип мероприятия</Table.TextHeaderCell>
                                            <Table.TextHeaderCell>Ссылка</Table.TextHeaderCell>
                                            <Table.TextHeaderCell>Управление</Table.TextHeaderCell>
                                        </Table.Head>
                                        <Table.Body height={240}>
                                            {dataEvents.reverse().map((event) => (
                                                <Table.Row key={event.id}>
                                                    <Table.TextCell>{event.description}</Table.TextCell>
                                                    <Table.TextCell> <Badge color={event.typeEvent === 1 ? "green" : event.typeEvent === 2 ? "red" : event.typeEvent === 3 ? "yellow" : event.typeEvent === 4 ? "blue" : "neutral"} marginRight={8}>{event.type_event.nameEvent}</Badge></Table.TextCell>
                                                    <Table.TextCell >{event.typeEvent === 3 ? (<a href={event.linkEvent} target='_blank' rel="noopener noreferrer">{event.linkEvent}</a>) : 'Ссылка скрыта'}</Table.TextCell>
                                                    <Table.TextCell><span className='table-btn'  onClick={() => delEvent(event.id)} ><TrashCan strokeWidth={2} size={24}/></span></Table.TextCell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </div>
    )
}
export default CalendarPage