import React, { useState } from 'react';
import { HomeAlt1 ,Gear, Newspaper, Video} from 'akar-icons';
import { Link } from 'react-router-dom';

const BottomNavigation = () => {
    const [activeTab, setActiveTab] = useState('home');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <nav>
            <ul className="bottom-navigation">
                <li
                    className={`bottom-navigation-item`}
                
                >
                    <a href="/lk">
                        <div className='bottom-link-content'>
                            <HomeAlt1 size={24} strokeWidth={1.5}/>
                            <span>Главная</span>
                        </div>
                    </a>
                </li>
                <li
                    className={`bottom-navigation-item`}
                   
                >
                    <Link to="/lk/portfolio">
                        <div className='bottom-link-content'>
                        <Newspaper strokeWidth={1.5} size={24} />
                            <span>Портфолио</span>
                        </div>
                    </Link>
                </li>
                <li
                    className={`bottom-navigation-item`}
               
                >
                    <a href="/lk/video/grid/1">
                        <div className='bottom-link-content'>
                        <Video  strokeWidth={1.5} size={24} />
                            <span>Азбука</span>
                        </div>
                    </a>
                </li>
                <li
                    className={`bottom-navigation-item`}
                    
                >
                    <a href="/lk/video/grid/2">
                        <div className='bottom-link-content'>
                        <Video  strokeWidth={1.5} size={24} />
                            <span>Мудрость</span>
                        </div>
                    </a>
                </li>
                <li
                    className={`bottom-navigation-item`}
                    
                >
                    <Link to="/lk/anketa">
                        <div className='bottom-link-content'>
                            <Gear size={24} strokeWidth={1.5}/>
                            <span>Настройки</span>
                        </div>
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default BottomNavigation;