import React, { useEffect, useState } from 'react';
import '../styles/main.css';
import '../styles/lk.css';
import '../styles/flexboxgrid.min.css';
import HeaderLeft from '../components/headerLeft'
import { Pane, Badge, toaster } from 'evergreen-ui';

import axios from 'axios';



function NotifyPage(props) {
    const data = props.data;
    const profile = props.profile;

    const [mails, setMails] = useState([]);

    useEffect(() => {
        getMails();
    }, []);

    const getMails = async () => {
        try {
          const response = await axios.get('https://reps-club.com:8000/v1/mails', {
            headers: {
              'x-access-token': localStorage.getItem("accessToken")
            }
          });
          setMails(response.data);
        } catch (error) {
            toaster.notify('Новых уведомлений нет!', {
                id: 'forbidden-action',
            })
        }
      };
      
      const deleteMail = async (id) => {
        try {
          await axios.delete(`https://reps-club.com:8000/v1/mails/delete/${id}`, {
            headers: {
              'x-access-token': localStorage.getItem("accessToken")
            }
          });
          getMails(); 
        } catch (error) {
            toaster.danger('Упс! Ошибка удаления сообщения!', {
                id: 'forbidden-action',
            })
        }
      };
      
      const updateMailViewing = async (id) => {
        try {
          await axios.post(`https://reps-club.com:8000/v1/mail/view/${id}`, null, {
            headers: {
              'x-access-token': localStorage.getItem("accessToken")
            }
          });
          getMails(); 
        } catch (error) {
            toaster.danger('Упс! Что-то пошло не так, перезагрузите страницу!', {
                id: 'forbidden-action',
            })
        }
      };

    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft user={data} profileInfo={profile} />
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Уведомления</p>
                            </div>
                            <div className='lk-notify-list'>

                                {
                                    mails.length <= 0 ? (
                                        <div className='none-project'>
                                            <p>У тебя нет уведомлений 😔</p>
                                        </div>
                                    ) :
                                        mails.map((mail) => (
                                            <div className="item-notify" key={mail.id}  onMouseEnter={() => updateMailViewing(mail.id)}>
                                                <div className="type-notify">
                                                    <Pane>
                                                        <Badge display="inline-flex" color=  {mail.typeMail === 1 ? 'neutral' : 'blue'}>
                                                            {mail.typeMail === 1 ? 'Обычное' : 'Другое'}
                                                        </Badge>
                                                    </Pane>
                                                </div>
                                                <div className="text-notify">
                                                    <p>{mail.textMail}</p>
                                                </div>
                                                <button style={{border: '1px solid #000', background: 'transparent', padding: '8px 8px'}} onClick={() => deleteMail(mail.id)}>Удалить</button>
                                            </div>
                                        ))}
                            </div>
                        </section>

                    </main>
                </div>
            </div>


        </div>

    )


}
export default NotifyPage