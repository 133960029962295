import React, { useState } from 'react';
import '../styles/main.css';
import '../styles/lk.css';
import '../styles/flexboxgrid.min.css';
import HeaderLeft from '../components/headerLeft'
import { TextInputField, TextareaField, Button, toaster } from 'evergreen-ui'
import axios from 'axios';




function SupportPage(props) {
    
    const data = props.data;
    const profile = props.profile;

    const [description, setDescription] = useState("");
    const [question, setQuestion] = useState("");
    const [captchaInput, setCaptchaInput] = useState("");
    const [captcha, setCaptcha] = useState(generateCaptcha());
    const botApiKey = '6246505928:AAGJRRJeJS1wqjpdDHv4Py91iBNFxv2KpYA'

    function generateCaptcha() {
      //  const chars = "0123456789абвгдеежзийклмнопрстуфхцчшщэюя";
        const chars = "AaGJRRJeJS1wqjPdDHv4Py91iBNFxv2KpY";
        let randomString = "";
        for (let i = 0; i < 6; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            randomString += chars[randomIndex];
        }
        return randomString;
    }

    function refreshCaptcha() {
        setCaptchaInput('')
        setCaptcha(generateCaptcha());
    }

    const handleSubmitQuestion = (event) => {
        event.preventDefault();
        if (captchaInput === captcha) {
            axios.post("https://reps-club.com:8000/v1/question/send", {
                "question": question,
                "description": description,
                "authorId": data.id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    setDescription('')
                    setQuestion('')
                    refreshCaptcha();
                    axios.post(`https://api.telegram.org/bot${botApiKey}/sendMessage`, {
                        chat_id: '-1001758210686',
                        parse_mode: "Markdown",
                        text: `Пользователю *${profile.fio}* нужна ваша помощь!\nОписание проблемы:\n *${description}*, \nответить в личные сообщения: @${profile.telegram}`,
                      });


                    toaster.success('Сообщение в службу поддержки отправлено', {
                        id: 'forbidden-action',
                    })
                })
                .catch((error) => {
                    toaster.danger('Что-то пошло не так, попробуйте повторить еще раз', {
                        id: 'forbidden-action',
                    })
                });
        } else {

            toaster.danger('Вы не прошли проверку на бота, прпробуйте еще раз', {
                id: 'forbidden-action',
            })
            return refreshCaptcha();
        }
    }

    return (
        <div className='App'>
            <div className="container-lk">
                <div style={{ display: "flex", alignItems: "flex-start", width: '100%' }}>
                    <HeaderLeft  user={data} profileInfo={profile}/>
                    <main>
                        <section className='lk-section-default'>
                            <div className='lk-section-title'>
                                <p>Поддержка</p>
                            </div>
                            <div className='lk-support-content'>
                                <form method='post' id='eventAdd' onSubmit={handleSubmitQuestion}>
                                    <TextInputField
                                        inputHeight={48}
                                        label="Какой у Вас вопрос или проблема?"
                                        
                                
                                        placeholder="Напишите или выберите вопрос"
                                        onChange={(e) => setQuestion(e.target.value)}
                                        value={question}

                                    />

                                    <TextareaField
                                        label="Подробное описание проблемы"
                                        required
                                        description="Если вопрос связан с техническими проблемами, постарайтесь описать их более поробно, чтобы мы могли быстрее решить их"
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                    />

                                    <TextInputField
                                        maxWidth={420}
                                        inputHeight={48}
                                        label={"Проверка на бота, напиши эти символы: " + captcha}
                                        hint=""
                                        placeholder="Напишите правильный ответ"
                                        id="captcha" name="captcha"
                                        onChange={(e) => setCaptchaInput(e.target.value)}
                                        value={captchaInput}
                                    />
                                    <br />

                                    <Button width={'100%'} height={48} appearance="primary">
                                        Отправить
                                    </Button>
                                    {/* isLoading */}
                                </form>
                            </div>
                        </section>

                    </main>
                </div>
            </div>
        </div>
    )
}
export default SupportPage